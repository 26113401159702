import axios, { endpoints } from 'src/utils/axios';

export const getQuotedetails = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.purchaseOrder.getQuotedetails}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getAllQuotes = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.purchaseOrder.getAllAccounting)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getPOAllList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.purchaseOrder.getPOAllList)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updatePOsignedFile = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(endpoints.purchaseOrder.updateSignedFile + id, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateCOC = (id, file) => {
  new Promise((resolve, reject) => {
    axios
      .post(endpoints.purchaseOrder.updateCOC + id, { COC: file })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const generatePOPdf = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.purchaseOrder.purchaseOrderInvoice}${id}`, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
