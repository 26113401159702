import { Helmet } from 'react-helmet-async';
import VendorRegistrationView from 'src/sections/auth/vendor/vendor-register-view';
// sections

// ----------------------------------------------------------------------

export default function VendorRegistrationPage() {
  return (
    <>
      <Helmet>
        <title>Vendor Registration</title>
      </Helmet>

      <VendorRegistrationView />
    </>
  );
}
