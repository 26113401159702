import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui

import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hooks';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { Checkbox } from '@mui/material';
import ClientAutoComplete from 'src/components/custom/ClientAutoComplete';
import { createDeliveryTracking } from 'src/services/delivery';
import { LoadingButton } from '@mui/lab';
import UploadFile from 'src/components/upload/upload-file';
// ----------------------------------------------------------------------

export default function CreateQuoteForm({ onClose, fetchAllTrackings }) {
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const TrackingSchema = Yup.object().shape({
    trackingNumber: Yup.string().required('tracking is required'),
    courier: Yup.string().required('Courier is required'),
    deliveryChallan: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      trackingNumber: '',
      courier: '',
      deliveryChallan: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(TrackingSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await createDeliveryTracking({ ...data });
      fetchAllTrackings();
      // await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar('Create success!');
      router.push('/delivery/backendFieldTeam');
      onClose();
      // console.info('DATA', data);
    } catch (error) {
      enqueueSnackbar('Error in creating delivery tracking!', { variant: 'error' });
      console.error(error);
    }
  });

  // console.log(errors, values);
  const renderProperties = (
    <Grid xs={12} md={12}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RHFTextField
              sx={{ textTransform: 'uppercase' }}
              name="trackingNumber"
              label="Tracking Number"
            />
          </Grid>
          <Grid item xs={12}>
            <RHFTextField sx={{ textTransform: 'uppercase' }} name="courier" label="Courier" />
          </Grid>
          <Grid item xs={12}>
            <UploadFile
              label="challan"
              path="test/delivery/tracking/challan"
              error={errors?.deliveryChallan?.message}
              value={values.deliveryChallan}
              setValue={(val) => setValue('deliveryChallan', val)}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            style={{ textTransform: 'uppercase' }}
            loading={isSubmitting}
            size="large"
            type="submit"
            variant="contained"
          >
            Create Tracking
          </LoadingButton>
          <Button
            style={{ textTransform: 'uppercase' }}
            sx={{ ml: 2 }}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderProperties}
      </Grid>
    </FormProvider>
  );
}
