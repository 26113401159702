import { useState, memo, useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, InputAdornment, Link, Paper, MenuItem, Select, Stack, TextField } from '@mui/material';
import { convertCurrency, getAllCurrency } from 'src/services/common';
import Grid from '@mui/material/Unstable_Grid2';




// ----------------------------------------------------------------------

function VendorCurrencyConverter({
    readonly,
    targetCurrency,
    targetValue,
    sourceCurrencyVal,
    //handleUpdateValue,
    //index
  }) {

    const [currencyListing, setCurrencyListing] = useState([]);
    const [sourceCurrency, setSourceCurrency] = useState();
    const [sourcePrice, setSourcePrice] = useState();
    const [targetedPrice, setTargetedPrice] = useState();

    useEffect(() => {
      setSourceCurrency(sourceCurrencyVal)
      getAllCurrency().then((response) => {
        setCurrencyListing(response);
      });
    }, []);

    useEffect(() => {
      setTargetedPrice(targetValue)
    }, [targetValue]);

    //setting up source price whenever we have target price by default
    useEffect(() => {
      if(targetValue){
        convertCurrency(targetValue, targetCurrency, sourceCurrencyVal).then((res) => {
          let sourceVal = res ? parseFloat(res.toFixed(3)) : res
          setSourcePrice(sourceVal)
        });
      }
    }, []);

    const onChangeCurrency = async (e) => {
      if(targetValue)
        await convertCurrency(targetValue, targetCurrency, e.target.value).then((res) => {
              let targetVal = parseFloat(res.toFixed(3))
              setSourcePrice(targetVal)
              setSourceCurrency(e.target.value);
            //   handleUpdateValue(fieldName, targetVal, id,index)
          });
      else{
        setSourceCurrency(e.target.value);
      }
    };

    const  handleBlur = async (e) => {
      if(sourcePrice)
      await convertCurrency(sourcePrice, sourceCurrency, targetCurrency).then((res) => {
            let targetVal = parseFloat(res.toFixed(3))
            setTargetedPrice(targetVal)
            // handleUpdateValue(fieldName, targetVal, id,index)
        });
    };
 
  return (
    <Stack spacing={1}>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
                variant="outlined"
                type="text"
                multiline
                sx={{ 
                  maxWidth: 100, 
                  height: '100%'
                }}
                InputProps={{
                  style: {
                    color: 'black',
                  },
                }}
                // disabled={true}
                value={sourcePrice}
                //onChange={(e) => {handleTextFieldChange('eshopPrice', e, row?._id); setSourcePrice(e.target.value)}}
                // onChange={(e) => { setSourcePrice(e.target.value)}}
                onBlur={handleBlur}
              />
          </Grid>

          <Grid item>
            <Select disabled={true} sx={{ maxWidth: 150, height: '80%' }} size="small" label="Currency" defaultValue={sourceCurrencyVal} value={sourceCurrency} onChange={onChangeCurrency}>
                {currencyListing?.map((currency) => (
                  <MenuItem key={currency?.abbreviation} value={currency?.abbreviation}>
                    {`${currency?.abbreviation} (${currency?.symbol})`}
                  </MenuItem>
                ))}
              </Select>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              variant="outlined"
              type="text"
              multiline
              sx={{ maxWidth: 100, height: '100%' }}
              // disabled={true}
              InputProps={{
                style: {
                  color: 'black',
                },
              }}
              value={targetedPrice}
              // onChange={(e) => handleUpdateValue(fieldName, e, id)}
            />
          </Grid>

          <Grid item>
            <Select
              sx={{ maxWidth: 150, height: '80%' }}
              size="small"
              label="Currency"
              defaultValue={targetCurrency} 
              value={targetCurrency}
              disabled
              onChange={onChangeCurrency}
            >
              {currencyListing?.map((currency) => (
                <MenuItem key={currency?.abbreviation} value={currency?.abbreviation}>
                  {`${currency?.abbreviation} (${currency?.symbol})`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
    </Stack>
  );
}

export default memo(VendorCurrencyConverter);
