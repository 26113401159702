import React from 'react';
import { Box, Button, Chip, Container, Typography, alpha } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';

import { useParams, useRouter } from 'src/routes/hooks';
import { CurrencyPicker } from 'src/components/currency-picker/currency-picker';
import VendorQuoteTable from '../vendorQuoteTable';

export default function VendorQuoteView() {
  const settings = useSettingsContext();
  const router = useRouter();
  const { id } = useParams();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="Vendor Orders"
        links={[
          { name: 'Home', href: '/' },
          {
            name: 'Vendor Orders',
            href: '/vendor-orders',
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            style={{ borderRadius: 1, backgroundColor: primaryPresets[2].darkest, width: 240 }}
            variant="outlined"
            onClick={() => router.replace(`/quote/${id}/vendor/vendor-selection`)}
          >
            <Typography textTransform="uppercase" variant="button">
              Submission of Quote
            </Typography>
          </Button>
          <Button
            style={{ borderRadius: 1, backgroundColor: primaryPresets[6].main, width: 240 }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button">
              Orders
            </Typography>
          </Button>
          <Button
            style={{ borderRadius: 1, backgroundColor: primaryPresets[7].main, width: 240 }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button">
              Archive
            </Typography>
          </Button>
        </Box>
        <CurrencyPicker />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: 1,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 2,
          mt: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box>
          <Chip style={{ borderRadius: 1, marginRight: 10,textTransform:"uppercase" }} label="Project ID: 1087" />
          <Chip style={{ borderRadius: 1,textTransform:"uppercase" }} label="RFQ: ABC123" variant="outlined" />
          <Chip style={{ borderRadius: 1,textTransform:"uppercase" }} label="RFQ NAME: TEST123" variant="outlined" />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography textTransform='uppercase' variant="caption">{'Recently edited by - {User Name}'}</Typography>
          <Box sx={{ mt: 1.7 }} display="flex" justifyContent="space-between" alignItems="center">
            <Button style={{ borderRadius: 1 }} variant="outlined">
              <Typography textTransform="uppercase" variant="button">
                EDIT
              </Typography>
            </Button>
            <Button style={{ borderRadius: 1, marginLeft: 32 }} variant="outlined">
              <Typography textTransform="uppercase" variant="button">
                SUBMIT
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <VendorQuoteTable />
    </Container>
  );
}
