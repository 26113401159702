import {
  Box,
  Button,
  MenuItem,
  Collapse,
  Paper,
  CircularProgress,
  Checkbox,
  IconButton,
  Typography,
  styled,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Link,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Iconify from 'src/components/iconify/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useParams } from 'src/routes/hooks';
import UploadFile from 'src/components/upload/uploadFileButton';
import CommentsModal from 'src/components/comments/index';
import CurrencyConverter from 'src/layouts/_common/currency_converter';

import * as Yup from 'yup';
import { useSnackbar } from 'src/components/snackbar';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';

import {
  getQuotedProjectDetails,
  createEshopRequest,
  updateEshopRequest,
} from 'src/services/eshop';
import { getAllVendorApprovedRequestsEshop } from 'src/services/vendorDashboard';
import { primaryPresets } from 'src/theme/options/presets';
import CountComment from 'src/components/comments/CountComment';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function createData(
  id,
  slNo,
  desc,
  pn,
  complianceDeclaration,
  qty,
  deliveryTimeline,
  unitIssue,
  make,
  deliveryLeadTimeUnit,
  purchaseUnit,
  purchaseTotal,
  datasheetUpload,
  certificateConformity,
  vendorOnline,
  purchasePrice,
  finalPrice,
  comment,
  approvalStatus
) {
  return {
    id,
    slNo,
    desc,
    pn,
    complianceDeclaration,
    qty,
    deliveryTimeline,
    unitIssue,
    make,
    deliveryLeadTimeUnit,
    purchaseUnit,
    purchaseTotal,
    datasheetUpload,
    certificateConformity,
    vendorOnline,
    purchasePrice,
    finalPrice,
    comment,
    approvalStatus,
  };
}

function createSecondaryData(
  unitIssue,
  make,
  deliveryTimeline,
  deliveryLeadTimeUnit,
  purchaseUnit,
  purchaseTotal,
  datasheetUpload,
  certificateConformity,
  vendorOnline,
  purchasePrice,
  finalPrice,
  comment,
  approvalStatus
) {
  return {
    unitIssue,
    make,
    deliveryTimeline,
    deliveryLeadTimeUnit,
    purchaseUnit,
    purchaseTotal,
    datasheetUpload,
    certificateConformity,
    vendorOnline,
    purchasePrice,
    finalPrice,
    comment,
    approvalStatus,
  };
}

export default function EshopTable(props) {
  const [formData, setFormData] = useState({});
  const { loading, data } = props || {};
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);

  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data, data?.length]);

  // console.log(data,"data table primary")
  const COLUMNS = [
    { id: 'slNo', label: 'SNO.', minWidth: 50 },

    {
      id: 'description',
      label: 'Description',
      minWidth: 100,
      render: (value, row) => (value ? <Typography sx={{ width: 320 }}>{value}</Typography> : '--'),
    },
    {
      id: 'PN',
      label: 'P/N',
      minWidth: 100,
    },
    {
      id: 'Compliance',
      label: 'Compliance Declaration',
      minWidth: 100,
    },
    {
      id: 'quantity',
      label: 'QUANTITY',
      minWidth: 100,
    },
    {
      id: 'deliveryLeadTime',
      label: 'Delivery Timeline',
      minWidth: 80,
      render: (value, row) => (value ? `${value} ${row?.deliveryLeadTimeUnit}` : '-'),
    },
  ];

  const SECONDARY_COLUMNS = [
    {
      id: 'unitIssue',
      label: 'Unit of Issue',
      minWidth: 170,
      render: (values) => values.unit,
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 170,
      render: (values) => values.make,
    },
    {
      id: 'deliveryTimeline',
      label: 'Delivery Timeline',
      minWidth: 170,
      render: (values) => (
        <RHFTextField
          label="Delivery Time Line"
          name={'deliveryTimeline'}
          size="small"
          sx={{ mb: 1.2, width: 150, textTransform: 'uppercase' }}
        />
      ),
    },
    {
      id: 'deliveryLeadTimeUnit',
      label: 'Delivery Time Unit',
      minWidth: 170,
      render: (value, row) => (
        <RHFSelect
          label="Delivery Time Unit"
          name="deliveryLeadTimeUnit"
          size="small"
          defaultValue={value?.deliveryLeadTimeUnit}
          sx={{ mb: 1.2, width: 150, textTransform: 'uppercase' }}
        >
          <MenuItem value="Days">DAYS</MenuItem>
          <MenuItem value="Weeks">WEEKS</MenuItem>
          <MenuItem value="Months">MONTHS</MenuItem>
        </RHFSelect>
      ),
    },

    {
      id: 'datasheetUpload',
      label: 'Datasheet File Upload',
      minWidth: 170,
      render: (values, errors, setValue) => (
        <UploadFile
          label="datasheet"
          path={`test/quote/${id}/eshop/datasheet`}
          error={errors?.dataSheetFile?.message}
          value={values.dataSheetFile}
          setValue={(val) => setValue('dataSheetFile', val)}
        />
      ),
    },
    {
      id: 'certificateConformity',
      label: 'Certificate of Conformity',
      minWidth: 170,
      render: (values, errors, setValue) => (
        <UploadFile
          label="COC"
          path={`test/quote/${id}/eshop/datasheet`}
          error={errors?.COC?.message}
          value={values.COC}
          setValue={(val) => setValue('COC', val)}
        />
      ),
    },
    {
      id: 'vendorOnline',
      label: 'Vendor Online',
      minWidth: 133,
      render: () => (
        <Box sx={{ width: 133 }} display="flex" flexDirection="column">
          <Box sx={{ mb: 0.3 }} display="flex" flexDirection="row">
            <Typography
              textTransform="uppercase"
              variant="caption"
              component="span"
              sx={{ mr: 2.2 }}
            >
              Sourcing Field - ESHOP
            </Typography>
            <Iconify width={20} icon="ic:outline-edit" />
          </Box>
          <RHFTextField
            inputProps={{
              style: {
                height: 70,
              },
            }}
            name={'eshopLink'}
            size="small"
            sx={{ mb: 1.2, width: 150, textTransform: 'uppercase' }}
            multiline
          />

          <Box sx={{ mb: 0.3, mt: 1.5 }} display="flex" flexDirection="row">
            <Typography
              textTransform="uppercase"
              variant="caption"
              component="span"
              sx={{ mr: 2.2 }}
            >
              Company Name
            </Typography>
            <Iconify width={20} icon="ic:outline-edit" />
          </Box>
          <RHFTextField
            inputProps={{
              style: {
                height: 70,
              },
            }}
            name={'eshopName'}
            size="small"
            sx={{ mb: 1.2, width: 150, textTransform: 'uppercase' }}
            multiline
          />
        </Box>
      ),
    },
    {
      id: 'purchasePrice',
      label: 'Purchase Price',
      minWidth: 280,
      render: (values, errors, setValue, mainData, handleUpdatePriceValue) => (
        <CurrencyConverter
          // title={"Eshop"}
          fieldName={'price'}
          // id={row?._id}
          readonly={false}
          targetCurrency={values?.currency?.abbreviation}
          targetValue={values?.price}
          handleUpdateValue={handleUpdatePriceValue}
        />
      ),
    },

    {
      id: 'comment',
      label: 'Comment',
      minWidth: 170,
      render: (values, row) => (
        <Box onClick={() => handleComments(values)} display="flex" flexDirection="column">
          <Box sx={{ ml: 2.5 }}>
            <Iconify width={20} icon="ic:outline-chat" />
          </Box>
          <CountComment projectId={values} isCommentOpen={values} />
          <Typography
            textTransform="uppercase"
            variant="caption"
            component="span"
            sx={{ mr: 0.4, width: 20 }}
          >
            View/Write Comment
          </Typography>
        </Box>
      ),
    },
    {
      id: 'approvalStatus ',
      label: 'Approval Status ',
      minWidth: 100,
      render: () => (
        <Box display="flex" flexDirection="column">
          {/* <Button variant="contained">
            <Typography textTransform="uppercase">Review</Typography>
          </Button> */}
          <Box sx={{ mb: 0.3, mt: 1.8 }} display="flex" flexDirection="row">
            <Typography
              textTransform="uppercase"
              variant="caption"
              component="span"
              sx={{ mr: 2.2 }}
            >
              Quantity
            </Typography>
            <Iconify width={20} icon="ic:outline-edit" />
          </Box>
          <RHFTextField
            name={'quantity'}
            size="small"
            sx={{ mb: 2.3, width: 100, textTransform: 'uppercase' }}
            type="number"
          />
          <Typography
            textTransform="uppercase"
            style={{ textDecoration: 'underline' }}
            variant="subtitle2"
            component="span"
            sx={{ mb: 0.8 }}
          >
            filename.pdf
          </Typography>
          <Button
            component="label"
            variant="contained"
            startIcon={<Iconify width={20} icon="carbon:upload" />}
          >
            <Typography textTransform="uppercase">Upload</Typography>
            <VisuallyHiddenInput accept="*" onClick={(e) => console.log('e===', e)} type="file" />
          </Button>
        </Box>
      ),
    },
  ];

  const SECONDARY_TABLE_DATA = [
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
  ];

  const onChangeText = (field, val) => {
    setFormData({ ...formData, [field]: val.target.value });
  };

  return (
    <Box>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <CollapsableTable
        colSpan={8}
        primaryColumns={COLUMNS}
        primaryTableData={tableData}
        secondaryColumns={SECONDARY_COLUMNS}
        secondaryTableData={SECONDARY_TABLE_DATA}
        loading={loading}
        enableCheckBox={false}
      />
    </Box>
  );
}

function CollapsableTable(props) {
  const {
    primaryColumns,
    primaryTableData,
    secondaryColumns,
    colSpan,
    loading = false,
    enableCheckBox = true,
  } = props || {};

  const table = useTable({ defaultRowsPerPage: 10 });
  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = primaryTableData.map((n) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (_event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <Paper elevation={1}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar>
          <Table stickyHeader sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                {/* <TableCell /> */}
                {/* {enableCheckBox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < primaryTableData.length
                      }
                      checked={
                        primaryTableData.length > 0 && selected.length === primaryTableData.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )} */}
                {primaryColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      textTransform: 'uppercase',
                      border: 1,
                      textAlign: 'center',
                      background: primaryPresets[6].lighter,
                      color: primaryPresets[11].main,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  padding="checkbox"
                  sx={{ background: primaryPresets[6].lighter, color: primaryPresets[11].main }}
                >
                  Eshop Order
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      width="100%"
                      height={220}
                    >
                      <CircularProgress />
                      <br />
                      <Typography textTransform="uppercase">Fetching...</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {primaryTableData.map((row, index) => {
                  const isItemSelected = isSelected(row?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row
                      colSpan={colSpan}
                      key={row?.code}
                      secondaryColumns={secondaryColumns}
                      row={{ ...row, slNo: index + 1 }}
                      columns={primaryColumns}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                      enableCheckBox={enableCheckBox}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        </Scrollbar>
      </TableContainer>
    </Paper>
  );
}
function Row(props) {
  const {
    row,
    columns,
    secondaryColumns,

    colSpan = 6,
    isItemSelected = false,
    labelId = '',
    handleClick = () => null,
    enableCheckBox = true,
  } = props || {};

  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [quotedProjectData, setQuotedProjectdata] = useState([]);

  useEffect(() => {
    getAllVendorApprovedRequestsEshop(id, row._id).then((res) => {
      setQuotedProjectdata([...res]);
    });
  }, []);

  const HandleAddRow = () => {
    setQuotedProjectdata([...quotedProjectData, {}]);
  };
  const HandleRemoveRow = async (indexToRemove) => {
    const updatedItems = await quotedProjectData?.map((item, index) => {
      if (index === indexToRemove) {
        if (item?.isQuoteSubmitted) {
          return item;
        }
      } else {
        return item;
      }
    });
    // const updatedItems = quotedProjectData.filter((item, index) => index !== indexToRemove);
    setQuotedProjectdata(updatedItems);
  };

  return (
    <>
      <TableRow
        onClick={(event) => handleClick(event, row?.id)}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.code}
        sx={{ cursor: 'pointer' }}
      >
        {enableCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              key={column.id}
              align="center"
              style={{ verticalAlign: 'top', textTransform: 'uppercase', border: '1px solid gray'}}
            >
              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                  '&:hover': {
                    textDecoration: 'none',
                    color: 'your_hover_color', // Replace 'your_hover_color' with the desired color
                  },
                }}
                href={`/quote/${id}/eshop/eshop-orders-delivery/${row?._id}`}
              >
                {column.render ? column.render(value, row) : value}
              </Link>
            </TableCell>
          );
        })}
        <TableCell
          style={{
            align: 'center', border: '1px solid black'
          }}
        >
          <Button
            style={{ textTransform: 'uppercase'}}
            variant="outlined"
            href={`/quote/${id}/eshop/eshop-orders-delivery/${row?._id}`}
          >
            VIEW
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const CustomTableRow = ({
  COLUMNS,
  row,
  mainData,
  id,
  quotedProjectData,
  setQuotedProjectdata,
  index,
  HandleRemoveRow,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const QuoteSchema = Yup.object().shape({
    // compliance: Yup.string(),
    quantity: Yup.number().min(1).required('quantity is required'),
    // HSN: Yup.string(),
    // COC: Yup.string(),
    deliveryLeadTimeUnit: Yup.string(),
    eshopName: Yup.string().required('Required'),
    eshopLink: Yup.string().required('Required'),
    // make: Yup.string(),
    deliveryTimeline: Yup.number('Enter number').min(1),
    price: Yup.number().min(1).required('price is required'),
    // vendorQuoteFile: Yup.string(),
    dataSheetFile: Yup.string(),
    // description: Yup.string(),
    // image: Yup.string(),
  });
  const defaultValues = useMemo(
    () => ({
      compliance: row?.Compliance || mainData?.Compliance,
      quantity: row?.quantity || 0,
      HSN: row?.HSN || mainData?.HSN,
      COC: row?.COC || '',
      unit: mainData?.unit || '',
      make: row?.make || mainData.make,
      deliveryTimeline: row?.deliveryTimeline,
      deliveryLeadTimeUnit: row?.deliveryLeadTimeUnit,
      price: row?.price,
      eshopName: row?.eshopName,
      eshopLink: row?.eshopLink,
      vendorQuoteFile: mainData?.vendorQuoteFile || '',
      dataSheetFile: row?.dataSheetFile || '',
      description: mainData?.description || row?.description,
      // image: mainData?.image || '',
      currency: mainData?.currency,
      _id: row?._id,
      isQuoteSubmitted: row?.isQuoteSubmitted,
    }),
    [row]
  );

  const methods = useForm({
    resolver: yupResolver(QuoteSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,

    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const handleUpdatePriceValue = (fieldName, targetVal, id) => {
    setValue('price', targetVal);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (row?.isQuoteSubmitted) {
        updateEshopRequest(row?._id, { ...data, currency: data?.currency?._id });
        enqueueSnackbar('Quote updated ');
      } else {
        await createEshopRequest({
          ...data,
          projectId: id,
          partId: mainData._id,
          currency: data?.currency?._id,
        });
        enqueueSnackbar('Quote submitted ');
        setValue('isQuoteSubmitted', true);
      }
    } catch (error) {
      enqueueSnackbar('Error in creating project!', { variant: 'error' });
    }
  });

  return (
    <FormProvider {...methods}>
      <StyledTableRow tabIndex={-1} key={row.code} style={{ borderBottom: '1px solid' }}>
        {COLUMNS.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align} style={{ textTransform: 'uppercase' }}>
              {column.render
                ? column.render(values, errors, setValue, mainData, handleUpdatePriceValue)
                : value}
            </TableCell>
          );
        })}
        <TableCell
          style={{
            position: 'sticky',
            right: 0,
            background: '#fafafa',
            borderBottom: '1px solid #f0f0f0',
            zIndex: 100,
            borderLeft: '1px solid #fafafa',
            align: 'center',
          }}
        >
          <Button
            style={{ textTransform: 'uppercase' }}
            variant="outlined"
            href={`/quote/${id}/eshop/eshop-orders-delivery/${mainData?._id}`}
          >
            VIEW
          </Button>
        </TableCell>
      </StyledTableRow>
    </FormProvider>
  );
};
