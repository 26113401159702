import { Helmet } from 'react-helmet-async';
import FrontendAccClientView from 'src/sections/frontend-acc-client/view';
// sections

// ----------------------------------------------------------------------

export default function FrontendAccClientPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Client (FrontEnd) - Accounting</title>
      </Helmet>

      <FrontendAccClientView />
    </>
  );
}
