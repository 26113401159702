import axios, { endpoints } from 'src/utils/axios';
import { identifier } from 'stylis';

export const getQuotes = (page, rowsPerPage) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.quote.getAll}?page=${page}&pageSize=${rowsPerPage}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const createQuotes = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.create}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateQuotes = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.update}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const uploadManualFile = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.uploadManual}/${id}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const deleteUploadedManualFile = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.deleteManualFile}/${id}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getQuote = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.quote.get}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateItems = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.additems}`, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const setl2complete = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.l2Complete}${id}`)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const markForApproval = (id, body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.markForApproval}${id}`, body)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const SetApproval = (id, body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.approveItem}${id}`, body)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const SetArchiveQuote = (id, body={}) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.archiveQuote}${id}`, body)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const SetRestoreQuote = (id, body={}) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.restoreQuote}${id}`, body)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const addBenifitAndShipping = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.addBenifitAndShipping}`, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const approveQuote = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.approveQuote}${id}`)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const reviewQuote = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.reviewQuote}`, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const addPo = (id, data) => {
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.addPo}${id}`, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const UpdateHSN = (id, lineitemId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${endpoints.quote.updateHSN}${id}/${lineitemId}`, data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};
export const SET_GR_APPROVAL = (id, commercialId, Payload) => {
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.setGRApproval}${id}/${commercialId}`, Payload)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const SET_PAYMENT_STATUS = (id, commercialId, Payload) => {
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.setPaymentStatus}${id}/${commercialId}`, Payload)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const SET_INVOICE_BOOKED = (id, commercialId,  Payload) => {
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.quote.setInvoiceBooked}${id}/${commercialId}`, Payload)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export const elasticSearchApi = (text) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.quote.search}${text}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  //Rollback

  // export const rollbackQuoteService = (id) => {
  //   new Promise((resolve, reject) => {
  //     axios
  //       .post(`${endpoints.common.rollback}${id}/quote`)
  //       .then((response) => resolve(response))
  //       .catch((err) => reject(err));
  //   });
  // };

  export const rollbackQuoteService = (id) => {
    return axios.post(`${endpoints.common.rollback}${id}/quote`)
      .then((response) => response.data)
      .catch((error) => {
        throw error; // Re-throw the error with the response data
      });
  };
  
  export const rejectQuoteService = (id) => {
    return axios.post(`${endpoints.common.reject}${id}/quote/confirm_yes`)
      .then((response) => response.data)
      .catch((error) => {
        throw error; // Re-throw the error with the response data
      });
  };

  export const enquireRejectQuoteService = (id) => {
    return axios.post(`${endpoints.common.reject}${id}/quote/enquire`)
      .then((response) => response.data)
      .catch((error) => {
        throw error; // Re-throw the error with the response data
      });
  };
