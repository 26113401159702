import axios, { endpoints } from 'src/utils/axios';

export const getSignedUploadURl = (filename) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.file.getuploadUrl}?fileName=${filename}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getSignedURltoviewFile = (filepath) =>
  new Promise((resolve, reject) => {
    const url = encodeURIComponent(filepath)
    axios
      .get(`${endpoints.file.getfileUrl}?fileName=${url}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
