import React from 'react';
import { fCurrency } from 'src/utils/format-number';
import { Stack, Typography, Divider } from '@mui/material';
import {
  calculateFinalAmount,
  calculateDiscountAmount,
  calculateTaxAmount,
  calculateContractAmount,
} from 'src/utils/quoteHelperFunc';
export default function ContractAmountDisplay({ data }) {
  return (
    <>
      <Stack sx={{color:"white"}}>
        <Typography textTransform="uppercase" variant="subtitle1" gutterBottom>
          Total contract amount
        </Typography>
        <Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Subtotal</Typography>
            <Typography variant="body2" textTransform="uppercase" >
              {fCurrency(calculateContractAmount(data), data?.currency?.symbol)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">
              Discount ({(data?.addondiscountrate || 0) + '%'})
            </Typography>
            <Typography textTransform="uppercase" variant="body2" >
              - &nbsp;
              {fCurrency(calculateDiscountAmount(data), data?.currency?.symbol)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Tax</Typography>
            <Typography variant="body2" textTransform="uppercase">
              + {fCurrency(calculateTaxAmount(data), data?.currency?.symbol)}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Shipping</Typography>
            <Typography variant="body2" textTransform="uppercase">
              + {fCurrency(data?.addonshippingHandling, data?.currency?.symbol)}
            </Typography>
          </Stack>
          <Divider sx={{ my: 1, borderColor:'red' }} />
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body" textTransform="uppercase">Total</Typography>
            <Typography variant="subtitle1" textTransform="uppercase">
              {fCurrency(calculateFinalAmount(data), data?.currency?.symbol)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
