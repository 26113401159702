import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Container, Typography, alpha, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import BackendAccVendorLevel1Table from '../BackendAccVendorLevel2Table';
import { getAllVendorApprovedRequests } from 'src/services/vendorAcc';
import { useParams } from 'src/routes/hooks';
import CommonNavigation from 'src/components/custom/CommonNavigation';
import { useSnackbar } from 'src/components/snackbar';
import { HasAccess } from '@permify/react-role';

export default function BackendAccVendorLevel1View() {
  const settings = useSettingsContext();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [vendorData, setVendorData] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [isTriggered, setIsTriggered] = useState(false);

  useEffect(() => {
    getAllVendorApprovedRequests(id).then((res) => {
      if (res.status === 404) {
        enqueueSnackbar('No Line Item avaliable');
      } else {
        setProjectData(res.project);
        setVendorData(res.vendors);
      }
    });
  }, [isTriggered]);

  const handleTriggered = () => {
    setIsTriggered(!isTriggered);
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box display="flex">
        <Box sx={{ width: '100%'}}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box
              sx={{ width: '40%', borderRight: '1px solid white', pr: 3, mb: 2 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box>
                <CustomBreadcrumbs
                  heading="ACCOUNTING VENDOR"
                  links={[
                    { name: 'Home', href: '/home' },
                    { name: 'ACCOUNTING LIST', href: '/home/accounting' },
                    { name: 'ACCOUNTING VENDOR Level 2', href: '/home' },
                  ]}
                  sx={{ mb: 2, color: 'white' }}
                />
                <Stack>
                  <Stack sx={{ mb:2 }} direction={'row'} justifyContent={'space-between'}>
                  <Chip
                      textTransform="uppercase"
                      label={`PROJECT ID : ${projectData?.projectId}`}
                      style={{ background: '#71ccd9', width: '100%' }}
                    />
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'}>

                  <Typography
                    sx={{ color: 'white' }}
                    variant="span"
                    gutterBottom
                    textTransform="uppercase"
                  >
                    CURRENCY : {projectData?.currency?.symbol} {projectData?.currency?.abbreviation}
                  </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Typography
                sx={{ color: 'white' }}
                variant="h4"
                gutterBottom
                textTransform="uppercase"
              >
                Level 2
              </Typography>
            </Box>
            <Box
              sx={{ width: '60%', pr: 2, pl: 2 }}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box
                sx={{
                  pr: 3,
                  pl: 3,
                  pt: 4,
                  mb:2,
                  width:'100%',
                  color: 'white',
                }}
              >
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '30%' }}>PROJECT ID</Box>:
                  <Box sx={{ width: '70%', pl: 3 }}>{projectData?.projectId}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '30%' }}>RFQ NO.</Box>:
                  <Box sx={{ width: '70%', pl: 3, wordWrap: 'break-word' }}>{projectData?.RFQ}</Box>
                </Box>
                <Box display="flex" sx={{ width: '100%', mb: 1 }}>
                  <Box sx={{ width: '30%' }}>RFQ NAME</Box>:
                  <Box sx={{ width: '70%', pl: 3, wordWrap: 'break-word' }}>{projectData?.RFQName}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} >
            <HasAccess
              roles={['admin']}
              permissions={['accounting_frontend_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/frontend-acc-client/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  ml:2,
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level1_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting CLIENT L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_client_backend_level2_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/accounting-client-level2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting CLIENT L2
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level1_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l1/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: 'none'
                }}
              >
                Accounting Vendor L1
              </Button>
            </HasAccess>
            <HasAccess
              roles={['admin']}
              permissions={['accounting_vendor_backend_level2_view']}
              // renderAuthFailed={<p>You are not authorized to access!</p>}
            >
              <Button
                href={`/acc-vendor/l2/${id}`}
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  borderRadius: '0px',
                  marginRight: '10px',
                  color:'black',
                  borderTop: '2px solid red'
                }}
              >
                Accounting Vendor L2
              </Button>
            </HasAccess>
            <HasAccess sx={{m:0, p:0}} roles={['admin']} permissions={['delivery_l2']}>
            <Button
              href = {`/delivery/deliveryl2/${id}`}
              sx={{
                backgroundColor: 'gray',
                borderRadius: '0px',
                marginRight: '10px',
              }}
            >
              Delivery
            </Button>
          </HasAccess>
          </Box>
        </Box>
      </Box>
      {projectData && projectData?.line_items ? (
        <>
          

          <BackendAccVendorLevel1Table
            data={vendorData.map((i, index) => ({
              ...i,
              index: index + 1,
              line_item: projectData.line_items?.find((j) => j._id == i.partId),
            }))}
            setVendorData={setVendorData}
            projectData={projectData}
            handleTriggered={handleTriggered}
          />
        </>
      ) : (
        <Typography>NO DATA AVAILABLE FOR PROVIDED PROJECT</Typography>
      )}
    </Container>
  );
}
