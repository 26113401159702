import { FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';

function CurrencyPicker({ onChange, error, selectedValue, size, disabled }) {
  const [currencyListing, setCurrencyListing] = useState([]);
  const [currencySelected, setCurrencySelected] = useState('');
  const [loading, setLoading] = useState(false);
  const { currency } = useAuthContext();


  useEffect(() => {
    setCurrencyListing(currency);
  }, [currency]);
  useEffect(() => {
    setCurrencySelected(selectedValue);
  }, [selectedValue]);
  return (
    <FormControl error={error}>
      <InputLabel style={{ textTransform: 'uppercase', marginTop: '0px' }}>Currency</InputLabel>
      <Select
        defaultValue={selectedValue || currencySelected}
        value={selectedValue || currencySelected}
        sx={{ maxWidth: 280, minWidth: 240, textTransform: 'uppercase' }}
        size={size}
        disabled={disabled}
        placeholder="Select currency"
        onChange={(event) => {
          // Call the parent component's onChange function if provided
          if (onChange) {
            onChange(
              event.target.value,
              currencyListing.find((i) => i._id === event.target.value)
            );
            setCurrencySelected(event.target.value);
          }
        }}
      >
        {loading ? (
          <MenuItem value="">
            <CircularProgress color="inherit" size={20} />
          </MenuItem>
        ) : (
          currencyListing?.map((currencyVal) => (
            <MenuItem key={currencyVal?.abbreviation} value={currencyVal?._id}>
              {`${currencyVal?.abbreviation} (${currencyVal?.symbol})`}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
}

export default CurrencyPicker;
