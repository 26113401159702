import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useParams, useRouter } from 'src/routes/hooks';
import { useOutletContext } from 'react-router';
import VendorSelectionTable from '../VendorSelectionTable';

export default function VendorSelectionView() {
  const settings = useSettingsContext();
  const router = useRouter();
  const { id } = useParams();
  const [data, loading] = useOutletContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Box display="flex">
        <CustomBreadcrumbs
          heading="Vendor Selection"
          links={[
            { name: 'Home', href: '/home' },
            {
              name: 'Quotes',
              href: '/home/quote',
            },
            {
              name: 'Vendor',
              href: `/quote/${id}/vendor`,
            },
            {
              name: 'ESHOP ',
              href: `/quote/${id}/eshop`,
            },
            {
              name: 'ESHOP Order',
              href: `/quote/${id}/eshop/eshop-orders`,
            },
            {
              name: 'Vendor Selection',
              href: `/quote/${id}/vendor/vendor-selection`,
            },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Box borderLeft={2} color={'white'} sx={{ pl: 2, ml: 2 }}>
          <Button
            variant="outlined"
            sx={{ background: 'white', color: 'black', borderRadius:"20px" }}
            onClick={() => router.push(`/quote/l1/${id}`)}
          >
            <Typography textTransform="uppercase" variant="button">
              Quote Level 1
            </Typography>
          </Button>
          <br />
          <Button
            sx={{ background: 'white', color: 'black', borderRadius:"20px", marginTop:"10px" }}
            variant="outlined"
            onClick={() => router.push(`/quote/l2/${id}`)}
          >
            <Typography textTransform="uppercase" variant="button">
              Quote Level 2
            </Typography>
          </Button>
        </Box>
        <Box borderLeft={2} color={'white'} sx={{ pl: 2, ml: 2, width:'20%' }} display='flex' flexDirection='column'>
       {/* <Box sx={{ p: 2, color: 'white' }} elevation={0}> */}

        <Typography textTransform="uppercase" display="flex" variant="caption">
        <Typography sx={{width:'50%'}}>Project ID </Typography><Typography>: {data?.projectId}</Typography>
        </Typography>
        <Typography textTransform="uppercase" display="flex" variant="caption">
        <Typography sx={{width:'50%'}}>RFQ</Typography><Typography>: {data?.RFQ}</Typography>
        </Typography>
        <Typography textTransform="uppercase" display="flex" variant="caption">
        <Typography sx={{width:'50%'}}>RFQ NAME</Typography> <Typography>: {data?.RFQName}</Typography>
        </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between">
      </Box>
      <VendorSelectionTable
        loading={loading}
        data={data?.line_items}
        baseCurrency={data.currency}
      />
    </Container>
  );
}
