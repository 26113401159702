import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
//
import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();

  const { user } = useMockedUser();

  const navData = useNavData();

  return (
    <AppBar
      component="nav"
      sx={{
        top: 65,
        py: 0,
      }}
    >
      {/* <Toolbar
        sx={{
          py: 0,
          justifyContent: 'flex-start',
          borderBottom: '1px solid white',
          display: 'flex',
          alignItems: 'center',
          ...bgBlur({
            color: theme.palette.background.default,
          })
        }}
      > */}
      <div style={{
                  py: 0,
          justifyContent: 'flex-start',
          borderBottom: '1px solid white',
          display: 'flex',
          alignItems: 'center',
          minHeight:"50px",
          margin:0,
          padding:0,
          backgroundColor: theme.palette.background.default
      }}>
        <NavSectionHorizontal
          data={navData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
        </div>
      {/* </Toolbar> */}

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
