import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { primaryPresets } from 'src/theme/options/presets';
import { useRouter } from 'src/routes/hooks';
export default function VendorQuotes({ id, url,vid, currentTab }) {
  const router = useRouter();
  return (
    // <Box>
    //   <Button
    //     style={{ borderRadius: 1, backgroundColor: primaryPresets[2].darkest, width: 240 }}
    //     variant="outlined"
    //     onClick={() => vid ? router.replace(`${url}/vendor-quote-submission/${vid}`):router.replace(`${url}/vendor-quote-submission`)}
    //   >
    //     <Typography textTransform="uppercase" variant="button">
    //       Submission of Quote
    //     </Typography>
    //   </Button>
    //   <Button
    //     style={{ borderRadius: 1, backgroundColor: primaryPresets[6].main, width: 240 }}
    //     variant="outlined"
    //     onClick={() => vid ? router.replace(`${url}/vendor-quote-orders/${vid}`):router.replace(`${url}/vendor-quote-orders`)}
    //   >
    //     <Typography textTransform="uppercase" variant="button">
    //       Orders
    //     </Typography>
    //   </Button>
    //   <Button
    //     style={{ borderRadius: 1, backgroundColor: primaryPresets[7].main, width: 240 }}
    //     variant="outlined"
    //     onClick={() => vid? router.replace(`${url}/vendor-quote-archive/${vid}`):router.replace(`${url}/vendor-quote-archive`)}
    //   >
    //     <Typography textTransform="uppercase" variant="button">
    //       Archive
    //     </Typography>
    //   </Button>
    // </Box>
    <Box display={'flex'} >
              <Button
                variant="contained"
                onClick={() => vid ? router.replace(`${url}/vendor-quote-submission/${vid}`):router.replace(`${url}/vendor-quote-submission`)}
                sx={{
                  borderRadius: 0,
                  color:"black",
                  ml: 1,
                  width: 240,
                  fontSize: 14,
                  backgroundColor: 'grey',
                  mt:(currentTab=="quote"?0:1),
                  borderTop: (currentTab=="quote"?'5px solid red':'')
                }}
              >
                 Submission of Quote
              </Button>
              <Button
                        onClick={() => vid ? router.replace(`${url}/vendor-quote-orders/${vid}`):router.replace(`${url}/vendor-quote-orders`)}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray',
                mt:(currentTab=="order"?0:1),
                  borderTop: (currentTab=="order"?'5px solid red':'')}}
              >
                ORDERS
              </Button>
              <Button
        onClick={() => vid? router.replace(`${url}/vendor-quote-archive/${vid}`):router.replace(`${url}/vendor-quote-archive`)}
                variant="contained"
                sx={{ borderRadius: 0,width: 240,color:"black", ml: 1, fontSize: 14, backgroundColor: 'gray',
                mt:(currentTab=="archive"?0:1),
                  borderTop: (currentTab=="archive"?'5px solid red':'') }}
              >
                ARCHIVE
              </Button>
          </Box>
  );
}
