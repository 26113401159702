import { Helmet } from 'react-helmet-async';
import { VendorLoginView } from 'src/sections/auth/vendor';
// sections

// ----------------------------------------------------------------------

export default function VendorLoginPage() {
  return (
    <>
      <Helmet>
        <title>Vendor Login</title>
      </Helmet>

      <VendorLoginView />
    </>
  );
}
