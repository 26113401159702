import axios, { endpoints } from 'src/utils/axios';

export const getAccountingDetails = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.accounting.getAccountingDetails}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const AdminPaymentApprove = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.adminPaymentApprove}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });


export const AdminInitiatePaymentApprove = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.initiatePaymentApproval}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const AdminPaymentDisapprove = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.accounting.adminPaymentApprove}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const addAdvanceAmmount = (id, body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.addAccountingAdvance}${id}`, body)
      .then((response) => resolve(response.data.newQuote))
      .catch((error) => reject(error));
  });


  export const CalculateAdvancePercentagePrice = (id, advancePercentage) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.accounting.calculateAdvancePercentagePrice}${id}/${advancePercentage}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const RequestAdvanceAdminApproval = (id,body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.requestAdvanceAdminApproval}${id}`,body)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

  export const ApproveLatestProformaAdvanceByAdmin = (id,body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.approveLatestProformaAdvance}${id}`,body)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

  export const RecivedLatestProformaAdvance = (id,body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.recivedLatestProformaAdvance}${id}`,body)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

  export const GetAllDeliveriesByProject = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.accounting.getAllDeliveriesByProject}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const MapCommercialInvoice = (id,body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.mapCommercialInvoice}${id}/commercial-invoice`,body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const GetAllCommercialInvoiceMappingByProjectId = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.accounting.mapCommercialInvoice}${id}/commercial-invoices`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const DeleteCommercialInvoiceMapping = (id,name) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`${endpoints.accounting.mapCommercialInvoice}${id}/commercial-invoice/${name}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const UpdateMappedCommercialInvoice = (id,body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accounting.mapCommercialInvoice}${id}/update-commercial-invoice`,body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
  
