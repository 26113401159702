import axios, { endpoints } from 'src/utils/axios';

export const getQuotePdfDetails = (type) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.pdfGenerate.getpdfDetails}${type}`)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const generateQuotePdf = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateQuotePdf}${id}`, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        // const blob = new Blob([response.data], { type: 'application/pdf' });
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // // a.download = 'quote.pdf';
        // a.click();
        // window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });

export const generateProformaPdf = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateProformaPdf}${id}`, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        // const blob = new Blob([response.data], { type: 'application/pdf' });
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'proformaInvoice.pdf';
        // a.click();
        // window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });

export const generateCommercialPdf = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateCommercialPdf}${id}`, data, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'commercialInvoice.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });

  export const generateBinderPdf = (id,comId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.pdfGenerate.generateBinderPdf}/${id}/${comId}`,{},
      {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `BinderFile-${id}-${comId}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(response);
      })
      .catch((err) => reject(err));
  });
