import { Box, Button, Typography } from '@mui/material';
import { primaryPresets } from 'src/theme/options/presets';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HasAccess } from '@permify/react-role';
import { select } from 'src/theme/overrides/components/select';

function CommonNavigation({ selectedTab }) {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(selectedTab);
  const HandleChangeParams = (tabId) => {
    setCurrentTab(tabId);
    navigate(`/home/${tabId}`);
  };
  useEffect(() => {
    setCurrentTab(selectedTab);
  }, [selectedTab]);

  return (
    <Box>
      <HasAccess
        roles={['admin']}
        permissions={['quote_frontend_view']}
        // renderAuthFailed={<p>You are not authorized to access!</p>}
      >
        <Button
          style={{
            borderRadius: 1,
            backgroundColor:
              currentTab === 'quote' ? primaryPresets[9].light : primaryPresets[9].lighter,
            color: currentTab === 'quote' ? '' : 'gray',
            width: 160,
            border: currentTab === 'quote' ? '1.5px solid' : '',
            textTransform: 'uppercase',
          }}
          variant="outlined"
          onClick={() => HandleChangeParams('quote')}
        >
          <Typography textTransform="uppercase" variant="button" b>
            QUOTE
          </Typography>
        </Button>
      </HasAccess>
      <HasAccess
        roles={['admin']}
        permissions={['po_frontend_view']}
        // renderAuthFailed={<p>You are not authorized to access!</p>}
      >
        <Button
          style={{
            borderRadius: 1,
            backgroundColor:
              currentTab === 'poStage' ? primaryPresets[6].light : primaryPresets[6].mint,
            color: currentTab === 'poStage' ? '' : 'gray',
            width: 160,
            border: currentTab === 'poStage' ? '1.5px solid' : '',
          }}
          variant="outlined"
          onClick={() => HandleChangeParams('poStage')}
        >
          <Typography textTransform="uppercase" variant="button" b>
            PO STAGE
          </Typography>
        </Button>
      </HasAccess>
      <HasAccess
        roles={['admin']}
        permissions={['accounting_frontend_view']}
        // renderAuthFailed={<p>You are not authorized to access!</p>}
      >
        <Button
          style={{
            borderRadius: 1,
            backgroundColor:
              currentTab === 'accounting' ? primaryPresets[2].darkest : primaryPresets[2].mild,
            color: currentTab === 'accounting' ? '' : 'gray',
            width: 160,
            border: currentTab === 'accounting' ? '1.5px solid' : '',
          }}
          variant="outlined"
          onClick={() => HandleChangeParams('accounting')}
        >
          <Typography textTransform="uppercase" variant="button" b>
            ACCOUNTING
          </Typography>
        </Button>
      </HasAccess>

      <HasAccess
        roles={['admin']}
        permissions={['client_delivery_l1']}
        // renderAuthFailed={<p>You are not authorized to access!</p>}
      >
        <Button
          style={{
            borderRadius: 1,
            backgroundColor:
              currentTab === 'delivery' ? primaryPresets[8].light : primaryPresets[8].lighter,
            color: currentTab === 'delivery' ? '' : 'gray',
            width: 160,
            border: currentTab === 'delivery' ? '1.5px solid' : '',
          }}
          variant="outlined"
          onClick={() => HandleChangeParams('delivery')}
        >
          <Typography textTransform="uppercase" variant="button">
            DELIVERY
          </Typography>
        </Button>
      </HasAccess>
      <HasAccess
        roles={['admin']}
        permissions={['vendor_frontend_view']}
        // renderAuthFailed={<p>You are not authorized to access!</p>}
      >
        <Button
          style={{
            borderRadius: 1,
            backgroundColor:
              currentTab === 'vendor' ? primaryPresets[9].light : primaryPresets[9].lighter,
            color: currentTab === 'vendor' ? '' : 'gray',
            width: 160,
            border: currentTab === 'vendor' ? '1.5px solid' : '',
          }}
          variant="outlined"
          onClick={() => HandleChangeParams('vendor')}
        >
          <Typography textTransform="uppercase" variant="button" b>
            VENDOR
          </Typography>
        </Button>
      </HasAccess>
      <HasAccess
        roles={['admin', 'field_team']}
        permissions={['delivery_frontend_view', 'field_team_level4_view']}
        // renderAuthFailed={<p>You are not authorized to access!</p>}
      >
        <Button
          style={{
            borderRadius: 1,
            backgroundColor:
              currentTab === 'deliveryl4' ? primaryPresets[8].light : primaryPresets[8].lighter,
            color: currentTab === 'deliveryl4' ? '' : 'gray',
            width: 160,
            border: currentTab === 'deliveryl4' ? '1.5px solid' : '',
          }}
          variant="outlined"
          onClick={() => HandleChangeParams('deliveryl4')}
        >
          <Typography textTransform="uppercase" variant="button">
            DELIVERY L4
          </Typography>
        </Button>
      </HasAccess>
    </Box>
  );
}

export default CommonNavigation;
