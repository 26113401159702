import React, { Component, useState } from 'react';
import { Button, DialogContent, ListItem, ListItemText, Divider, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getSignedURltoviewFile } from 'src/services/file';
import { fCurrency } from 'src/utils/format-number';
import { HasAccess } from '@permify/react-role';
import { generateLedgerPDF } from 'src/services/vendorAcc';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

export default function Ledger({ data, invoices, currency,sx }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const openlink = async (i) => {
    let file = await getSignedURltoviewFile(i);
    window.open(file);
  };

  const { enqueueSnackbar } = useSnackbar();


  async function downloadLedgerFile(data) {
    setLoading(true)

   await  generateLedgerPDF(data._id).then((resp)=>{
    const pdfData = resp;
    const url = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
    const a = document.createElement('a');
    a.href = url;
    a.download = `${data.projectId}-LEDGER.pdf`;
    a.click();
    setLoading(false)
    enqueueSnackbar("LEDGER PDF GENERATED");

   }).catch((err)=>{
    enqueueSnackbar("Failed to generate PDF", { variant: 'error' });
    setLoading(false)
    // console.log("ERR", err)
   })
    
  }
  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open} fullWidth>
        <DialogTitle>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography textTransform="uppercase" variant="h6" color={'black'}>
            Accounting ledger
          </Typography>
          <LoadingButton style={{textTransform:"uppercase"}}
            loading={loading}
            disabled={(invoices && invoices.length === 0)}
            variant="contained"
            onClick={() => downloadLedgerFile(data)}
          >
            DOWNLOAD LEDGER
          </LoadingButton>
        </div>
        </DialogTitle>

        <DialogContent sx={{ minHeight: 300 }}>
          {invoices?.map((i) => (
            <>
              <ListItem
                secondaryAction={
                  <HasAccess
            roles={['admin']}
            permissions={['accounting_frontend_view_ledger']}
            renderAuthFailed={<p></p>}
          >
            {i.path ? 
                  <Button
                    style={{ textTransform: 'uppercase' }}
                    size={'small'}
                    variant="contained"
                    onClick={() => openlink(i.path)}
                  >
                    Download invoice
                  </Button>:null}
                </HasAccess>
                }
              >
                <ListItemText
                  primary={
                    <Typography textTransform="uppercase" variant="h6" color={'primary'}>
                      {fCurrency(i.amount, currency)}{" "}
            {i?.isPaymentReceived? <Typography color='success' sx={{fontSize:12}}>Recived</Typography>:<Typography sx={{fontSize:12}} color='error'>Not Recived</Typography>}

                    </Typography>
                  }
                  secondary={new Date(i?.date || null).toDateString()}
                />
              </ListItem>
              <Divider />
            </>
          ))}
        </DialogContent>
      </Dialog>
      <Button
      sx={{
        color:"black",
              backgroundColor: '#D9D9D9',
              px: 5,
              borderRadius: 5,
              mt: 2,
              mr:2,
              ...sx
            }}
        style={{ textTransform: 'uppercase' }}
        variant={'contained'}
        onClick={() => setOpen(true)}
      >
        View Ledger
      </Button>
    </>
  );
}
