import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// auth
import { useAuthContext } from 'src/auth/hooks';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify/iconify';
import { Button, Divider, Grid } from '@mui/material';
import Wallpaper from 'src/assets/image/newWallpaer.jpg';
import LogoImage from 'src/assets/image/logo-new 1.png';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// ----------------------------------------------------------------------

export default function AuthClassicLayout({ children, image, title }) {
  const { method } = useAuthContext();

  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <AppBar position="static" sx={{ background: 'rgba(255,255,255,0.2)' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <img style={{ height: '60px' }} src={LogoImage} alt="log" />
          </Box>
          <Box>
            <Button
              style={{ textTransform: 'uppercase', backgroundColor: '#3EE2BA' }}
              variant="contained"
            >
              Request access
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <img
        src={Wallpaper}
        alt="background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1, // Ensure the image stays behind other content
        }}
      />

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          px: 4,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            px: 4,
            fontSize: '80px',
          }}
        >
          <div>
            <div style={{ color: 'white', fontWeight: 800 }}>WE DO</div>
            <div style={{ color: 'rgba(62, 226, 186, 1)', fontWeight: 800, lineHeight: '60px' }}>
              PLANNING
            </div>
          </div>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            px: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              px: 12,
              mt: 2,
              backgroundColor: 'rgba(239, 239, 239, 0.5)',
              padding: '24px 32px',
              borderRadius: '16px',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
