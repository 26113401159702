import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useFormContext, Controller } from 'react-hook-form';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import { getClients } from 'src/services/client';
import { useDebounce } from 'src/hooks/use-debounce';
import { FormHelperText } from '@mui/material';

const filter = createFilterOptions();

export default function   ClientAutoComplete({ name, setFieldValue }) {
  const { control } = useFormContext();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const fetchOptions = (searchValue) => {
    // Simulate an API call here. Replace with your actual API call.
    setIsLoading(true);

    // Example API call using setTimeout (remove this in production).
    getClients(searchValue).then((clients) => {
      setOptions([
        ...clients,
        { name: `Add new client "${searchValue}"`, isNew: true, typed: searchValue },
      ]);
      setIsLoading(false);
    });

    // Replace the setTimeout with your actual API call.
  };

  // Use debounce to throttle the fetchOptions function.
  const debouncedQuery = useDebounce(inputValue);

  React.useEffect(() => {
    if (debouncedQuery && debouncedQuery !== value?.name) fetchOptions(debouncedQuery);
  }, [debouncedQuery, value?.name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            {...field}
            value={field?.value}
            fullWidth
            onChange={(event, newValue) => {
              setValue(newValue);
              field.onChange(newValue);
              setFieldValue('clientAddress', newValue?.addresss);
              setFieldValue('shippingAddress', newValue?.shippingAddress);
            }}
            options={options}
            loading={isLoading}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              if (
                typeof newInputValue === 'string' &&
                newInputValue !== 'undefined' &&
                newInputValue
              ) {
                setInputValue(newInputValue);
              } else {
                setInputValue('');
                setValue(null);
              }
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="search-client"
            getOptionLabel={(option) => {
              if (option.isNew) {
                return option.typed;
              }
              return option.name;
            }}
            filterOptions={(x) => x}
            renderOption={(props, option) => (
              <li {...props}>
                {option.name} {option.clientId && `(${option.clientId})`}
              </li>
            )}
            freeSolo
            renderInput={(params) => (
              <TextField
                error={!!error}
                helperText={error ? 'Required field' : ''}
                {...params}
                label="Search for client"
                sx={{width:"40%"}}
              />
            )}
          />
        </>
      )}
    />
  );
}
