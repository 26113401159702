import { Helmet } from 'react-helmet-async';
import EshopSubmissionView from 'src/sections/eshop/view/eshopSubmission';
import React, { useEffect } from 'react';
import { HasAccess, usePermify } from '@permify/react-role';
import { useParams, useRouter } from 'src/routes/hooks';
// sections

// ----------------------------------------------------------------------

export default function EshopSubmission() {
  const { isAuthorized, isLoading } = usePermify();
  const router = useRouter();
   useEffect(() => {
     const callAuth = async() => {
       let tempAuth = await isAuthorized([],"backend");
       if(tempAuth) {
         router.push('404');
       }
     }
   callAuth();
   }, [])
  return (
    <>
      <Helmet>
        <title> Dashboard: Eshop Submission</title>
      </Helmet>

      <EshopSubmissionView />
    </>
  );
}
