import axios, { endpoints } from 'src/utils/axios';

export const createEshopRequest = async (data) => 
  new Promise((resolve, reject) => {
    axios.post(endpoints.eshop.quoteRequest, data)
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
  });
  export const deleteEshopRequest = async (id) => 
  new Promise((resolve, reject) => {
    axios.delete(`${endpoints.eshop.quoteRequest}/${id}`)
    .then((response) => resolve(response))
    .catch((error) => reject(error));
  });

export const updateEshopRequest = async (id, data) => {
  try {
    await axios.put(`${endpoints.eshop.quoteRequest}/${id}`, data);
    return { success: true, message: '' };
  } catch (err) {
    return { success: false, message: err?.data?.message };
  }
};

export const getQuotedProjectDetails = (projectId, partId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.eshop.getQuotedProjectDetails}${projectId}/${partId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const initiateChangeApprovalEshop = async (id, data) => {
    try {
      await axios.post(`${endpoints.vendor.initiateChangeApproval}${id}`, data);
      return { success: true, message: '' };
    } catch (err) {
      return { success: false, message: err?.data?.message };
    }
  };

  export const approvalChangeApprovalEshop = async (id, data) => {
    try {
      await axios.patch(`${endpoints.vendor.approvalChangeApproval}${id}`, data);
      return { success: true, message: '' };
    } catch (err) {
      return { success: false, message: err?.data?.message };
    }
  };

  export const getEshopRequestSuggestions = async (id) => 
    new Promise((resolve, reject) => {
      axios.get(`${endpoints.eshop.getEshopRequestSuggestionsUrl}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
    });