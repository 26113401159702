import SignatureCanvas from 'react-signature-canvas';
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Grid } from '@mui/material';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Image from 'src/components/image/image.js';
import Typography from '@mui/material/Typography';

export default function SignatureModal({ setValue, value }) {
  const signatureref = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openLightBox, setOpenLightBox] = React.useState(false);
  const [index, setIndex] = useState(0);

  const handleSave = () => {
    setValue({
      signature: signatureref.current.getTrimmedCanvas().toDataURL('image/png'),
    });
    handleClose();
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Signature</DialogTitle>
        <DialogContent>
          <SignatureCanvas
            penColor="black"
            ref={signatureref}
            canvasProps={{ style: { border: '1px solid #000000', width: '100%', height: '100%' } }}
            onEnd={(e) => {
              //setValue('signature', e.toDataURL());
            }}
          />
        </DialogContent>
        <Box display={'flex'} justifyContent={'flex-end'} px={2}>
          <Button
            variant="outlined"
            onClick={() => {
              signatureref.current.clear();
              setValue('signature', '');
            }}
          >
            Clear
          </Button>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

    
      <Dialog open={openLightBox} onClose={()=>{setOpenLightBox(false)}}>
        <DialogTitle>SIGNATURE</DialogTitle>
        <DialogContent>
        {/* <Grid container spacing={2} > */}
            {value.map((imageUrl, index) => (
              <Grid item key={index} xs={4}>
                <img
                  src={imageUrl}
                  alt={`Image ${index + 1}`}
                  width={"300px"}
                  height={"200px"}
                />
              </Grid>
            ))}
          {/* </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpenLightBox(false)}} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Typography variant="body" >
        CLICK TO VIEW SIGNATURE
      </Typography> */}
      {value.length != 0  ? (
        value.map((i, index) => (
          <>
          <Button variant="outlined" sx={{ mt: 2 }} onClick={() => {
                setOpenLightBox(true);}}>
          VIEW SIGNATURE
        </Button>
        {/* <Stack alignItems="baseline" direction="row" spacing={1}>
          <Stack direction="column" >
            <Image
              sx={{ width: 40, height: 40, borderRadius: 1, cursor: 'pointer' }}
              ratio="1/1"
              onClick={() => {
                setOpenLightBox(true);
                // setIndex(index);
              }}
              src={i}
            />
            </Stack>
            </Stack> */}
            </>
        ))

      ) : (
        <Button variant="outlined" sx={{ mt: 2 }} onClick={handleOpen}>
          Add Signature
        </Button>
      )}
    </>
  );
}
