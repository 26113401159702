import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useAuthContext } from 'src/auth/hooks';

import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes, vendorRoutes, deliveryL4Routes } from './dashboard';

import { componentsRoutes } from './components';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuthContext();
  const mainRoutes = user?.permissions?.length == 1 && user?.permissions[0] === "field_team_level4_view" && user?.role?.length ==1 && user?.role[0] === "field_team"  ? deliveryL4Routes : user?.role?.includes('vendor') ? vendorRoutes : dashboardRoutes;
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    // {
    //   path: '/',
    //   element: (
    //     <MainLayout>
    //       <HomePage />
    //     </MainLayout>
    //   ),
    // },

    // Auth routes
    ...authRoutes,
    // ...authDemoRoutes,

    // Dashboard routes
    ...mainRoutes,

    // Main routes
    // ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
