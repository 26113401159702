import { Helmet } from 'react-helmet-async';

import PurchaseOredrClientSection from 'src/sections/backendView/purchaseOrderClient';

export default function PurchaseOrderClient() {
  return (
    <>
      <Helmet>
        <title>Client View: PURCHASE ORDER Client Stage</title>
      </Helmet>
      <PurchaseOredrClientSection />
    </>
  );
}
