import axios, { endpoints } from 'src/utils/axios';

export const createNewVendor = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.auth.vendorRegister}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const statusCheck = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.dashboard.statusCheck)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getPendingProjectData = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.dashboard.pendingProjects)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getQuotedProjectData = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.dashboard.quotedProjects)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
export const getInProcessProjectData = () =>
  new Promise((resolve, reject) => {
    axios
      .get(endpoints.vendor.dashboard.inProcessProjects)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getVendorDetailsById = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.vendor.dashboard.getVendorById}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateVendorDetailsById = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(`${endpoints.vendor.dashboard.updateVendorById}${id}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
export const deleteVendor = async (id) =>
  new Promise((resolve, reject) => {
    axios
      .delete(endpoints.vendor.dashboard.deleteVendorById + id)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
export const acceptQuote = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.vendor.dashboard.acceptRequest}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getAllRequestofacceptedProjects = (id, partId, vid) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.vendor.dashboard.allRequestofProjects}${id}/${partId}/${vid ? vid : ''}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getAllVendorApprovedRequests = (id, partId, vid) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `${endpoints.vendor.dashboard.getAllVendorApprovedRequests}${id}/${partId}/${
          vid ? vid : ''
        }`
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getAllVendorApprovedRequestsEshop = (id, partId, vid) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.vendor.dashboard.getAllVendorApprovedRequestsEshop}${id}/${partId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
export const quoteRequest = (id, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.vendor.dashboard.quoteRequest}/${id}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
  export const quoteRequestSave = (id, data) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.vendor.dashboard.quoteRequest}-save/${id}`, data)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });