import { Helmet } from 'react-helmet-async';
import BackendAccVendorLevel2View from 'src/sections/backend-acc-vendor-level2/view';
// sections

// ----------------------------------------------------------------------

export default function BackendAccVendorLevel2Page() {
  return (
    <>
      <Helmet>
        <title> Dashboard: BackEnd: ACC. for Vendor - Level 2</title>
      </Helmet>

      <BackendAccVendorLevel2View />
    </>
  );
}
