import { Box, Chip, Container, Typography, alpha,  Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useParams } from 'src/routes/hooks';
import { useOutletContext } from 'react-router';
import VendorTable from '../VendorRequestTable';
import { useAuthContext } from 'src/auth/hooks';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HistoryIcon from '@mui/icons-material/History';
import { getQuote, updateQuotes } from 'src/services/quotes';

export default function VendorRequestView() {
  const settings = useSettingsContext();
  const { id } = useParams();
  const [data, loading] = useOutletContext();

  // const { user } = useAuthContext();
  // const { enqueueSnackbar } = useSnackbar();

  // const [refresh, setRefresh] = useState(0);
  // const [isRFQNameEdit, setIsRFQNameEdit] = useState(false);
  // const [isRFQNoEdit, setIsRFQNoEdit] = useState(false);
  // const [rfqNo, setRfqNo] = useState("");
  // const [rfqName, setRfqNumber] = useState("");
  // const [quoteData, setQuoteData] = useState([]);
  // useEffect(() => {
  //   getQuote(id).then((res) => setQuoteData(res));
  // }, [id, refresh]);

  
  // useEffect(() => {
  //   setRfqNo(data?.RFQ)
  // }, [data?.RFQ])
  // useEffect(() => {
  //   setRfqNumber(data?.RFQName)
  // }, [data?.RFQName])
  

  // async function updateRFQDetails(params) {
  //   let obj = {
  //     _id:id,
  //     userid:user._id
  //   }
  //   if(params == "RFQNAME"){
  //    obj["RFQName"] = rfqName;
  //   }else{
  //     obj["RFQ"] = rfqNo;
  //   }
  //   await updateQuotes({
  //     _id:id,
  //     RFQ:rfqNo,
  //     RFQName:rfqName,
  //     userid:user._id
  //   }).then((resp)=>{
  //     setRefresh(refresh + 1)
  //     enqueueSnackbar(resp.message);
  //     if(params === "RFQNAME"){
  //       setIsRFQNameEdit(false)
  //     }else{
  //       setIsRFQNoEdit(false)
  //     }
  //   }).catch((err)=>{
  //     console.log("err", err)
  //     enqueueSnackbar(err.data.message, { variant: 'error' });
  //   })
  // }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
       <Box display="flex">
      <CustomBreadcrumbs
        heading="Vendor Request"
        links={[
          { name: 'Home', href: '/home' },
          {
            name: 'Quotes',
            href: '/home/quote',
          },
          {
            name: 'Vendor',
            href: `/quote/${id}/vendor`,
          },
          {
            name: 'Vendor Request',
            href: `/quote/${id}/vendor/vendor-request`,
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
       <Box borderLeft={2} borderColor={'white'} paddingLeft={2} style={{ marginLeft: '10px' }}>
       {/* <Box sx={{ p: 2, color: 'white' }} elevation={0}> */}

       <Typography
              style={{ color: 'white' }}
              textTransform="uppercase"
              variant="caption"
            >{`Project ID: ${data?.projectId}`}</Typography>
            <br />{' '}
            {/* <Stack sx={{ flexDirection: 'row', marginBottom:isRFQNoEdit ? "20px" : "" }}>
              <Typography variant="caption" textTransform="uppercase" sx={{ marginTop:isRFQNoEdit ? "8px" : "" }}>
                RFQ:&nbsp;
              </Typography>
                {
                  isRFQNoEdit ?
                  <><TextField
                  id="margin-none"
                    variant="outlined"
                    size="small"
                    type="text"
                    multiline
                    value={rfqNo}
                    onChange={(e) => setRfqNo(e.target.value)}
                    sx={{ 
                      width: 240, height: '10px', color: 'blue', p: 0, marginTop: 0 , marginLeft:1,
                      '& .MuiOutlinedInput-root': {
                          margin: 0,
                        },
                    }}
                  />
                  <LoadingButton
                    style={{ textTransform: 'uppercase', width: '100px', marginTop:"3px", color:"white", marginLeft:10 }}
                    size="small"
                    disabled={rfqNo.length == 0}
                    type="submit"
                    variant="contained"
                    onClick = {() => {updateRFQDetails("RFQNO")}}
                  >
                    Update
                  </LoadingButton>
                  <HistoryIcon
                    title="BACK"
                     onClick={()=>setIsRFQNoEdit(false)} sx={{ marginLeft: 1, marginTop:"5px", color:"red", cursor: 'pointer'}}/>
                  </> :
                  <>
                  <Typography gutterBottom textTransform="uppercase" variant="caption">
                    {data?.RFQ}
                  </Typography>
                  {'  '}
                  <ModeEditIcon
                    title="RFQ NAME EDIT"
                    onClick={()=>setIsRFQNoEdit(true)} sx={{ marginLeft: 1, fontSize: 'medium', color:"red", cursor: 'pointer'}}/>
                  </>
              }
            </Stack>
            <Stack sx={{ flexDirection: 'row', marginBottom:isRFQNameEdit ? "20px" : "" }}>
              <Typography variant="caption" textTransform="uppercase" sx={{ marginTop:isRFQNameEdit ? "8px" : "" }}>
                RFQ Name:&nbsp;
              </Typography>
                {
                  isRFQNameEdit ?
                  <><TextField
                  id="margin-none"
                    variant="outlined"
                    size="small"
                    type="text"
                    multiline
                    value={rfqName}
                    onChange={(e) => setRfqNumber(e.target.value)}
                    sx={{ 
                      width: 240, height: '10px', color: 'blue', p: 0, marginTop: 0 , marginLeft:1,
                      '& .MuiOutlinedInput-root': {
                          margin: 0,
                        },
                    }}
                  />
                  <LoadingButton
                    style={{ textTransform: 'uppercase', width: '100px', marginTop:"3px", color:"white", marginLeft:10 }}
                    size="small"
                    disabled={rfqName.length == 0}
                    type="submit"
                    variant="contained"
                    onClick = {() => {updateRFQDetails("RFQNAME")}}
                  >
                    Update
                  </LoadingButton>
                  <HistoryIcon
                    title="BACK"
                     onClick={()=>setIsRFQNameEdit(false)} sx={{ marginLeft: 1, marginTop:"5px", color:"red", cursor: 'pointer'}}/>
                  </> :
                  <>
                  <Typography gutterBottom textTransform="uppercase" variant="caption">
                    {data?.RFQName}
                  </Typography>
                  {'  '}
                  <ModeEditIcon
                    title="RFQ NAME EDIT"
                    onClick={()=>setIsRFQNameEdit(true)} sx={{ marginLeft: 1, fontSize: 'medium', color:"red", cursor: 'pointer'}}/>
                  </>
              }
            </Stack> */}
            <Typography
              style={{ color: 'white' }}
              textTransform="uppercase"
              variant="caption"
            >{`RFQ: ${data?.RFQ}`}</Typography>
            <Typography
              style={{ color: 'white' }}
              textTransform="uppercase"
              variant="caption"
            >{`RFQ NAME: ${data?.RFQName}`}</Typography>
            <br />{' '}
            <Typography
              style={{ color: 'white' }}
              textTransform="uppercase"
              variant="caption"
            >{'Recently edited by - {User Name}'}</Typography>
            {/* </Box> */}
       </Box>
      </Box>
      <VendorTable loading={loading} data={data?.line_items} projectId={id} />
    </Container>
  );
}
