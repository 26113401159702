import axios, { endpoints } from 'src/utils/axios';

export const getAllNotification = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.notification.getAllNotification}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const addNewNotification = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.notification.addNotification}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const pickedIndividualNotification = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.notification.pickedIndividualNotification}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });