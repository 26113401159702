import axios, { endpoints } from 'src/utils/axios';
export const GET_COMMENTS_LIST = (ID, page, pageSize) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.comment.getCommentsList}/${ID}?page=${page}&pageSize=${pageSize}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const ADD_COMMENTS = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.comment.addComment}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
