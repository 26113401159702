import axios, { endpoints } from 'src/utils/axios';

export const getAllVendorApprovedRequests = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.accountingVendor.getAllVendorApprovedRequests}${id}`)
      .then((response) =>  resolve(response.data))
      // .catch((error) => reject(error));
      .catch((error) => {
        if(error?.status === 404){
          resolve(error)
        }else{
          reject(error);
        }
      });
  });

export const updateAccounting = (id, body) => {
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accountingVendor.updateAccountingdetails}${id}`, body)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const generatePO = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accountingVendor.generatePO}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const adminApproval = (id) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${endpoints.accountingVendor.adminApproval}${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });


export const generateLedgerPDF = (id) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${endpoints.accountingVendor.generateLedger}${id}`, { responseType: 'blob' })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
