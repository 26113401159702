import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import { CurrencyPicker } from 'src/components/currency-picker/currency-picker';
import EshopOrdersTable from '../eshopOrdersTable';

export default function EshopSubmissionView() {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="ESHOP"
        links={[
          { name: 'Home', href: '/' },
          {
            name: 'ESHOP',
            href: '/eshop',
          },
          {
            name: 'ESHOP Orders',
            href: '/eshop/eshop-orders',
          },
          {
            name: 'ESHOP Submission',
            href: '/eshop/eshop-orders/eshop-submission',
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Box sx={{ mb: 2 }} display="flex" justifyContent="space-between">
        <Box>
          <Button
            style={{ borderRadius: 1, backgroundColor: primaryPresets[2].darkest, width: 240 }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button">
              Submission of Quote
            </Typography>
          </Button>
          <Button
            style={{ borderRadius: 1, backgroundColor: primaryPresets[6].main, width: 240 }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button">
              Orders
            </Typography>
          </Button>
          <Button
            style={{ borderRadius: 1, backgroundColor: primaryPresets[7].main, width: 240 }}
            variant="outlined"
          >
            <Typography textTransform="uppercase" variant="button">
              Archive
            </Typography>
          </Button>
        </Box>
        <CurrencyPicker />
      </Box>

      <EshopOrdersTable />
    </Container>
  );
}
