/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    Box,
    Button,
    Chip,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
    InputAdornment,
  } from '@mui/material';
  import { useState, useEffect, useMemo } from 'react';
  import Iconify from 'src/components/iconify/iconify';
  import Scrollbar from 'src/components/scrollbar';
  import { TablePaginationCustom, useTable } from 'src/components/table';
  import AddMoreDialog from './addMoreDialog';
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
  import { RHFMultiSelect, RHFSelect, RHFTextField } from 'src/components/hook-form';
  import { useForm, FormProvider } from 'react-hook-form';
  import { yupResolver } from '@hookform/resolvers/yup';
  import * as Yup from 'yup';
  import { useSnackbar } from 'notistack';
  import { quoteRequest } from 'src/services/vendorDashboard';
  import { addDeliveryDetailsVendor } from 'src/services/delivery';
  import UploadMultipleFIles from 'src/components/upload/UploadMutipleFIlesButton';
  import UploadFileButton from 'src/components/upload/uploadFileButton';
  import UploadMultipleDocs from 'src/components/upload/UploadMultipleDocs';
  import GetTrackingStatus from 'src/components/custom/GetTrackingStatus';
  import PriceSelectV2 from 'src/components/custom/PriceSelectV2';
  import { primaryPresets } from 'src/theme/options/presets';
import VIewFile from 'src/components/upload/ViewFIle';
import PdfPreviewPopup from 'src/components/common/pdfPreview';
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  
  export default function VendorQuoteOrdersTable({ requestData, currency, refresh, setRefresh }) {

    const COLUMNS = [
      { id: 'slNo', label: 'SNO.', minWidth: 50, align: 'center' },
      {
        id: 'PN',
        label: 'PN',
        minWidth: 170,
        align: 'center',
        render: (val, row) => <Box>{row?.requestData[0]?.PN}</Box>,
      },
      {
        id: 'desc',
        label: 'Description',
        minWidth: 220,
        align: 'center',
        render: (val, row) => <Box>{row.requestData[0]?.description}</Box>,
      },
  
      {
        id: 'quantityApproved2',
        label: 'QUANTITY',
        minWidth: 170,
        render: (val, row) => <Box>{row?.requestData[0]?.quote?.quantity}</Box>,
      },
      {
        id: 'deliveryTimeline',
        label: 'Delivery Timeline',
        minWidth: 170,
        render: (val, row) => <Box>{row?.requestData[0]?.deliveryTimeline} {row?.requestData[0]?.deliveryLeadTimeUnit}</Box>,
      }
    ];
  
    const COLUMNS2 = [
      {
        id: 'unit',
        label: 'Unit of issue',
        minWidth: 150,
        render: (value) => <Box>{value}</Box>,
      },
      {
        id: 'make',
        label: 'Make',
        minWidth: 150,
        render: (value) => <Box>{value}</Box>,
      },
      {
        id: 'deliveryTimeline',
        label: 'DELIVERY TIMELINE',
        minWidth: 300,
        render: (value, row) => {
        return (<Box>{row?.deliveryTimeline} {row?.deliveryLeadTimeUnit}</Box>)},
      },
      // {
      //   id: 'price',
      //   label: 'ACTUAL UNIT PRICE',
      //   minWidth: 170,
      //   render: (value, row) => <Box>{row?.quote?.price}</Box>,
      // },
      // {
      //   id: 'estimatedShipping',
      //   label: 'ACTUAL TOTAL PRICE',
      //   minWidth: 170,
      //   render: (value, row) => <Box>{row?.quote?.price}*{row.quote?.quantity}</Box>,
      // },
    //   {
    //     id: 'description',
    //     label: 'ACTUAL shipping cost',
    //     minWidth: 170,
    //     align: 'center',
    //     render: (value, error, setValue) => (
    //       <>
    //         <Box display="flex" sx={{ minWidth: 180 }}>
              
    //         </Box>
    //       </>
    //     ),
    //   },
      {
        id: 'dataSheetFile',
        label: 'DATASHEET FILE UPLOAD',
        minWidth: 100,
        render: (value, error, setValue) => (
                <PdfPreviewPopup pdfUrl={value} label={`VIEW FILE`}/>

        )
      },
      {
        id: 'COC',
        label: 'CERTIFICATE OF CONFORMITY',
        minWidth: 100,
        render: (value, error, setValue) => (
                <PdfPreviewPopup pdfUrl={value} label={`VIEW FILE`}/>
        )
      },
      {
        id: 'eshopName',
        label: 'ESHOP ONLINE',
        minWidth: 210,
        render: (value, row) => <Box><a href={row.eshopLink}>{row.eshopName}</a></Box>,
      },
      {
        id: 'price2',
        label: 'ESHOP UNIT PRICE',
        minWidth: 210,
        render: (value, row) => <Box>{row.price}</Box>,
      },
      {
        id: 'quantity',
        label: 'Quantity',
        minWidth: 210,
        render: (value, row) => <Box>{row.quantity}</Box>,
      },
      {
        id: 'quantity1',
        label: 'ESHOP TOTAL PRICE',
        minWidth: 210,
        render: (value, row) => <Box>{row.price*row.quantity}</Box>,
      },
      {
        id: 'finalPrice',
        label: 'FINAL UNIT PRICE',
        minWidth: 210,
        render: (value, row) => <Box>{row.finalPrice}</Box>,
      },
      {
        id: 'quantityApproved',
        label: 'APPROVED QUANTITY',
        minWidth: 210,
        render: (value, row) => <Box>{row.quantityApproved}</Box>,
      },
      {
        id: 'quantityApproved1',
        label: 'FINAL TOTAL PRICE',
        minWidth: 210,
        render: (value, row) => <Box>{row.finalPrice*row.quantityApproved}</Box>,
      },

    ];
  
  
    function Row({ row, index, refresh, setRefresh }) {
      const [open, setOpen] = useState(true);
      const [delivery, setDelivery] = useState(row?.requestData || []);
      return (
        <>
          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
            <TableCell style={{ verticalAlign: 'top', textTransform: 'uppercase',  border: '1px solid gray' }}>
              <IconButton aria-label="expand row" size="small">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            {COLUMNS.map((column) => {
              const value = row[column.id];
              return (
                <TableCell
                  style={{ verticalAlign: 'top', textTransform: 'uppercase',  border: '1px solid gray'  }}
                  key={column.id}
                  align={column.align}
                >
                  {column.render ? column.render(value, row) : value}
                </TableCell>
              );
            })}
          </TableRow>
          {open ? (
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={open}>
                  <Box sx={{ margin: 1 }}>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          {COLUMNS2.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                textTransform: 'uppercase',
                                border: 1,
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {delivery.map((historyRow) => ( 
                          <CustomTableRow
                            row={historyRow}
                            COLUMNS={COLUMNS2}
                            currency={currency}
                            id={row._id}
                            refresh={refresh}
                            setRefresh={setRefresh}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          ) : null}
        </>
      );
    }
  
    return (
      <Box>
        <Paper elevation={1}>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Scrollbar sx={{ maxHeight: 1200 }}>
              <Table stickyHeader sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        minWidth: 50,
                        textTransform: 'uppercase',
                        border: 1,
                        background: primaryPresets[6].lighter,
                        color: primaryPresets[11].main,
                      }}
                    >
                      Expand
                    </TableCell>
                    {COLUMNS.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          textTransform: 'uppercase',
                          border: 1,
                          background: primaryPresets[6].lighter,
                          color: primaryPresets[11].main,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
  
                <TableBody>
                  {/* {requestData.map((row, index) => ( */}
                    <Row
                      row={{ requestData: requestData, slNo: 1 }}
                    //   index={index}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  {/* ))} */}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
  
        </Paper>
      </Box>
    );
  }
  
  // row with react hook form
  
  const CustomTableRow = ({ COLUMNS, row, currency, id, refresh, setRefresh }) => {

    return (
        <TableRow tabIndex={-1} key={row.code}>
          {COLUMNS.map((column) => {
            const value = row[column.id];
            return (
              <TableCell key={column.id} align={column.align} style={{ textTransform: 'uppercase',  border: '1px solid gray'  }}>
                {column.render ? column.render(value, row) : value}
              </TableCell>
            );
          })}
        </TableRow>
    );
  };
  