import { Box, Button, MenuItem, Typography, styled, InputAdornment, TextField, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import CollapsableTable from './collapsable-table-eshop';
import Iconify from 'src/components/iconify/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useParams } from 'src/routes/hooks';
import UploadFile from 'src/components/upload/uploadFileButton';
import CommentsModal from 'src/components/comments/index';

import CurrencyConverter from 'src/layouts/_common/currency_converter';
import CountComment from 'src/components/comments/CountComment';
import { values } from 'lodash';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function createData(
  id,
  slNo,
  desc,
  pn,
  complianceDeclaration,
  qty,
  deliveryTimeline,
  unitIssue,
  make,
  deliveryLeadTimeUnit,
  purchaseUnit,
  purchaseTotal,
  datasheetUpload,
  certificateConformity,
  vendorOnline,
  purchasePrice,
  finalPrice,
  comment,
  approvalStatus
) {
  return {
    id,
    slNo,
    desc,
    pn,
    complianceDeclaration,
    qty,
    deliveryTimeline,
    unitIssue,
    make,
    deliveryLeadTimeUnit,
    purchaseUnit,
    purchaseTotal,
    datasheetUpload,
    certificateConformity,
    vendorOnline,
    purchasePrice,
    finalPrice,
    comment,
    approvalStatus,
  };
}

function createSecondaryData(
  unitIssue,
  make,
  deliveryTimeline,
  deliveryLeadTimeUnit,
  purchaseUnit,
  purchaseTotal,
  datasheetUpload,
  certificateConformity,
  vendorOnline,
  purchasePrice,
  finalPrice,
  comment,
  approvalStatus
) {
  return {
    unitIssue,
    make,
    deliveryTimeline,
    deliveryLeadTimeUnit,
    purchaseUnit,
    purchaseTotal,
    datasheetUpload,
    certificateConformity,
    vendorOnline,
    purchasePrice,
    finalPrice,
    comment,
    approvalStatus,
  };
}

export default function EshopTable(props) {
  const [formData, setFormData] = useState({});
  const { loading, data } = props || {};
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);

  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    }
  }, [data, data?.length]);

  // console.log(data,"data table primary")
  const COLUMNS = [
    { id: 'slNo', label: 'SNO.', minWidth: 50 },

    {
      id: 'description',
      label: 'Description',
      minWidth: 100,
      render: (value, row) => (value ? <Typography sx={{ width: 320 }}>{value}</Typography> : '--'),
    },
    {
      id: 'PN',
      label: 'P/N',
      minWidth: 100,
    },
    {
      id: 'Compliance',
      label: 'Compliance Declaration',
      minWidth: 100,
      render: (value, row) => (value ? <Typography sx={{ width: 220 }}>{value}</Typography> : '--'),
    },
    {
      id: 'quantity',
      label: 'QUANTITY',
      minWidth: 100,
    },
    {
      id: 'deliveryLeadTime',
      label: 'Delivery Timeline',
      minWidth: 80,
      render: (value, row) => (value ? `${value} ${row?.deliveryLeadTimeUnit}` : '-'),
    },
  ];

  const SECONDARY_COLUMNS = [
    {
      id: 'unitIssue',
      label: 'Unit of Issue',
      minWidth: 170,
      render: (values) => values.unit,
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 170,
      render: (values) => values.make,
    },
    {
      id: 'deliveryTimeline',
      label: 'Delivery Timeline',
      minWidth: 170,
      render: (values,handleUpdateValue,errors) => (
        <TextField
          type={'number'}
          label="Delivery Time Line"
          name={'deliveryTimeline'}
          size="small"
          error={Boolean(errors.deliveryTimeline)}
            helperText={errors.deliveryTimeline || ''}
          value={values.deliveryTimeline}
          onChange={(e)=>handleUpdateValue("deliveryTimeline",e.target.value)}
          sx={{ mb: 1.2, width: 150, textTransform: 'uppercase' }}
        />
      ),
    },
    {
      id: 'deliveryLeadTimeUnit',
      label: 'Delivery Time Unit',
      minWidth: 170,
      render: (values, handleUpdateValue,errors) => (
     
        <Select  
          label="Delivery Time Unit"
          name="deliveryLeadTimeUnit"
          size="small"
          error={Boolean(errors.deliveryLeadTimeUnit)}
            helperText={errors.deliveryLeadTimeUnit || ''}
          defaultValue={values?.deliveryLeadTimeUnit}
          onChange={(e)=>handleUpdateValue("deliveryLeadTimeUnit",e.target.value)}

          sx={{ mb: 1.2, width: 150, textTransform: 'uppercase' }}
        >
          <MenuItem value="Days">DAYS</MenuItem>
          <MenuItem value="Weeks">WEEKS</MenuItem>
          <MenuItem value="Months">MONTHS</MenuItem>
        </Select>
      ),
    },

    {
      id: 'dataSheetFile',
      label: 'Datasheet File Upload',
      minWidth: 170,
      render: (values, handleUpdateValue, errors) => (
        <UploadFile
          label="datasheet"
          path={`test/quote/${id}/eshop/datasheet`}
          error={errors?.dataSheetFile}
          value={values.dataSheetFile}
          setValue={(val) => handleUpdateValue('dataSheetFile', val)}
        />
      ),
    },
    {
      id: 'COC',
      label: 'Certificate of Conformity',
      minWidth: 170,
      render: (values, handleUpdateValue, errors) => (
        <UploadFile
          label="COC"
          path={`test/quote/${id}/eshop/datasheet`}
          error={errors?.COC?.message}
          value={values.COC}
          setValue={(val) => handleUpdateValue('COC', val)}
        />
      ),
    },
    {
      id: 'eshopName',
      label: 'Vendor Online',
      minWidth: 133,
      render: (values,handleUpdateValue,errors) => (
        <Box display="flex" flexDirection="column">
          <Box sx={{ mb: 0.3 }} display="flex" flexDirection="row">
            <Typography
              textTransform="uppercase"
              variant="caption"
              component="span"
              sx={{ mr: 2.2 }}
            >
              Sourcing Field - ESHOP
            </Typography>
            <Iconify width={20} icon="ic:outline-edit" />
          </Box>
          <TextField
            inputProps={{
              style: {
                height: 70,
              },
            }}
            name='eshopLink'
            onChange={(e)=>handleUpdateValue("eshopLink",e.target.value)}
            value={values.eshopLink}
            size="small"
            sx={{ mb: 1.2, width: 150 }}
            multiline
            error={Boolean(errors.eshopLink)}
            helperText={errors.eshopLink || ''}
          />

          <Box sx={{ mb: 0.3, mt: 1.5 }} display="flex" flexDirection="row">
            <Typography
              textTransform="uppercase"
              variant="caption"
              component="span"
              sx={{ mr: 2.2 }}
            >
              Company Name
            </Typography>
            <Iconify width={20} icon="ic:outline-edit" />
          </Box>
          <TextField
            inputProps={{
              style: {
                height: 70,
              },
            }}
            value={values.eshopName}
          onChange={(e)=>handleUpdateValue("eshopName",e.target.value)}
            name='eshopName'
            size="small"
            sx={{ mb: 1.2, width: 150, textTransform: 'uppercase' }}
            multiline
            error={Boolean(errors.eshopName)}
        helperText={errors.eshopName || ''}
          />
        </Box>
      ),
    },
    {
      id: 'price',
      label: 'Purchase Price',
      minWidth: 280,
      render: (values, handleUpdateValue,errors) => (
        <>
        <CurrencyConverter
          // title={"Eshop"}
          fieldName='price'
          // id={row?._id}
          readonly={false}
          targetCurrency={values?.currency?.abbreviation}
          targetValue={values?.price}
          handleUpdateValue={handleUpdateValue}
          sourceCurrency={values?.currency?._id}
          // sourceValue={values?.price}
        />
        {errors.price && (
          <Typography color="error" variant='span'>{errors.price}</Typography>
        )}
        </>
      ),
    },

    {
      id: 'comment',
      label: 'Comment',
      minWidth: 170,
      render: (values, row) => {
        return (
          <Box
            disabled={true}
            onClick={() => handleComments(values)}
            sx={
              !values?.isQuoteSubmitted
                ? {
                    pointerEvents: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    opacity: '0.5',
                    cursor: 'not-allowed',
                  }
                : {}
            }
          >
            <Box sx={{ ml: 2.5 }}>
              <CountComment projectId={values?._id} isCommentOpen={false} />
            </Box>
            <Typography
              textTransform="uppercase"
              variant="caption"
              component="span"
              sx={{ mr: 0.4, width: 20 }}
            >
              View/Write Comment
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'quantity',
      label: 'Approval Status ',
      minWidth: 100,
      render: (values,handleUpdateValue, errors) => (
        <Box display="flex" flexDirection="column">
          {/* <Button variant="contained">
            <Typography textTransform="uppercase">Review</Typography>
          </Button> */}
          <Box sx={{ mb: 0.3, mt: 1.8 }} display="flex" flexDirection="row">
            <Typography
              textTransform="uppercase"
              variant="caption"
              component="span"
              sx={{ mr: 2.2 }}
            >
              Quantity
            </Typography>
            <Iconify width={20} icon="ic:outline-edit" />
          </Box>
          <TextField
            name='quantity'
            size="small"
            value={values.quantity}
            onChange={(e)=>handleUpdateValue("quantity",(e.target.value))}
            sx={{ mb: 2.3, width: 100, textTransform: 'uppercase' }}
            type="number"
            error={Boolean(errors.quantity)}
            helperText={errors.quantity || ''}
          />
          {/* <Typography
            textTransform="uppercase"
            style={{ textDecoration: 'underline' }}
            variant="subtitle2"
            component="span"
            sx={{ mb: 0.8 }}
          >
            filename.pdf
          </Typography>
          <Button
            component="label"
            variant="contained"
            startIcon={<Iconify width={20} icon="carbon:upload" />}
          >
            <Typography textTransform="uppercase">Upload</Typography>
            <VisuallyHiddenInput accept="*" onClick={(e) => console.log('e===', e)} type="file" />
          </Button> */}
        </Box>
      ),
    },
  ];

  const SECONDARY_TABLE_DATA = [
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
    createSecondaryData(1, 'PELCO', '24 days', 10, 10, '', '', '', '', '', '', ''),
  ];

  const onChangeText = (field, val) => {
    setFormData({ ...formData, [field]: val.target.value });
  };

  return (
    <Box>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <CollapsableTable
        colSpan={8}
        primaryColumns={COLUMNS}
        primaryTableData={tableData}
        secondaryColumns={SECONDARY_COLUMNS}
        secondaryTableData={SECONDARY_TABLE_DATA}
        loading={loading}
        enableCheckBox={false}
      />
    </Box>
  );
}
