import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Chip, Link, Stack } from '@mui/material';
import { primaryPresets } from 'src/theme/options/presets';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { calculateClientUnitPrice } from 'src/utils/quoteHelperFunc';
import { fCurrency } from 'src/utils/format-number';
import VIewFile from 'src/components/upload/ViewFIle';
import LineItemPosition from 'src/sections/_examples/mui/data-grid-view/CustomComponents/LineItemPosition';
import { LoadingButton } from '@mui/lab';
import { North, South } from '@mui/icons-material';
import { LineItemMoveDownApi, LineItemMoveUpApi } from 'src/services/common';
import { enqueueSnackbar } from 'notistack';
import PdfPreviewPopup from 'src/components/common/pdfPreview';
import Scrollbar from 'src/components/scrollbar';

function createData(
  id,
  sno,
  description,
  PN,
  clientUnitPrice,
  clientTotalPrice,
  quantity,
  make,
  deliveryTimeline,
  targetUnitPrice,
  venderPo,
  purchaseUnitPrice,
  totalPurchasePrice,
  cocFile,
  hsnCode,
  coo,
  index
) {
  return {
    id,
    sno,
    description,
    PN,
    clientUnitPrice,
    clientTotalPrice,
    quantity,
    make,
    deliveryTimeline,
    targetUnitPrice,
    venderPo,
    purchaseUnitPrice,
    totalPurchasePrice,
    cocFile,
    hsnCode,
    coo,
    index,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'sno', label: 'SNO.', minWidth: 50 },
  { id: 'description', label: 'Description', minWidth: 100 },
  { id: 'PN', label: 'P/N', minWidth: 100 },
  { id: 'quantity', label: 'QUANTITY', minWidth: 100 },
  {
    id: 'cocFile',
    label: 'COC File',
    maxWidth: 100,
    align:'center',
    render: (value) =>
      value ? (
        <VIewFile value={value} label="COC" />
      ) : (
        <Typography textTransform="uppercase" color="orange">
          Not Uploaded
        </Typography>
      ),
  },
  { id: 'hsnCode', label: 'HSN Code', minWidth: 150, align:'center' },
  { id: 'coo', label: 'COO', minWidth: 100 },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, data } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            style={{
              minWidth: headCell.minWidth,
              textTransform: 'uppercase',
              maxWidth: headCell.maxWidth
            }}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          textTransform="uppercase"
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          textTransform="uppercase"
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        />
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function PurchaseOrderL1Table({ lineItems, data, setRefresh, refresh }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10000);
  const [currSymbol, setCurrSymbol] = React.useState('$');
  React.useEffect(() => {
    if (lineItems?.length) {
      const rowArr = lineItems.map((e, ind) => {
        const vendor = e?.vendors
          ?.filter((i) => i.type == 'vendor')
          .map((vend) => {
            return {
              name: vend?.vendorId?.name ? vend?.vendorId?.name : '--',
              qty: vend?.quantityApproved ? vend?.quantityApproved : '--',
            };
          });
        const eShop = e?.vendors
          ?.filter((i) => i.type == 'eshop')
          ?.map((esh) => {
            return {
              name: esh?.eshopName ? esh?.eshopName : '--',
              qty: esh?.quantityApproved ? esh?.quantityApproved : '--',
            };
          });
        // console.log(data);
        return createData(
          e?._id,
          e?.serialNumber,
          e?.description ? e?.description : '--',
          e?.PN ? e?.PN : '--',
          fCurrency(calculateClientUnitPrice(e, data), data?.currency?.symbol),
          fCurrency(calculateClientUnitPrice(e, data) * e?.quantity, data?.currency?.symbol),
          e?.quantity ? e?.quantity : '--',
          e?.make ? e?.make : '--',
          e?.deliveryLeadTime ? `${e?.deliveryLeadTime} ${e?.deliveryLeadTimeUnit}` : '--',
          e?.targetPrice ? fCurrency(e?.targetPrice, data?.currency?.symbol) : '--',
          [vendor, eShop],
          e?.purchasePrice ? fCurrency(e?.purchasePrice, data?.currency?.symbol) : '--',
          e?.purchasePrice && e?.quantity
            ? fCurrency(e?.purchasePrice * e?.quantity, data?.currency?.symbol)
            : '--',
          e?.COC ? e?.COC : '',
          e?.HSN ? e?.HSN : '--',
          e?.COO ? e?.COO : '--',
          ind
        );
      });

      setRows(rowArr);
    }
  }, [lineItems]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const [open, setOpen] = React.useState(true);
  const handleClick1 = () => {
    setOpen(!open);
  };
  const [loading, setLoading] = React.useState(false);

  const MoveDown = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveDownApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };
  const MoveUp = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveUpApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Scrollbar sx={{ maxHeight: '80vh' }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      
                      {headCells.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{ textTransform: 'uppercase', maxWidth: column.maxWidth }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.render
                              ? column.render(
                                  value,
                                  row,
                                  open,
                                  handleClick1,
                                  data?._id,
                                  MoveDown,
                                  MoveUp,
                                  lineItems?.length
                                )
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Paper>
    </Box>
  );
}
