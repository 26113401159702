import { Helmet } from 'react-helmet-async';
import BackendAccVendorLevel1View from 'src/sections/backend-acc-vendor-level1/view';
// sections

// ----------------------------------------------------------------------

export default function BackendAccVendorLevel1Page() {
  return (
    <>
      <Helmet>
        <title> Dashboard: BackEnd: ACC. for Vendor - Level 1</title>
      </Helmet>

      <BackendAccVendorLevel1View />
    </>
  );
}
