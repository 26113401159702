import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import CollapsableTable from 'src/components/collapsable-table';
import CommentDialog from 'src/components/comment-dialog';
import Iconify from 'src/components/iconify/iconify';
import { primaryPresets } from 'src/theme/options/presets';
import { fCurrency } from 'src/utils/format-number';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function createData(checkBox, slNo, desc, pn, complianceDeclaration, qty, deliveryTimeline) {
  return {
    checkBox,
    slNo,
    desc,
    pn,
    complianceDeclaration,
    qty,
    deliveryTimeline,
  };
}

function createSecondaryData(
  unitIssue,
  hsnCode,
  make,
  vendorDeliveryTimeline,
  purchaseUnit,
  purchaseTotal,
  datasheetUpload,
  certificateConformity,
  vendorQuoteFile,
  comment,
  approvalStatus
) {
  return {
    unitIssue,
    hsnCode,
    make,
    vendorDeliveryTimeline,
    purchaseUnit,
    purchaseTotal,
    datasheetUpload,
    certificateConformity,
    vendorQuoteFile,
    comment,
    approvalStatus,
  };
}

export default function VendorQuoteTable() {
  const [formData, setFormData] = useState({});
  const [showDialog, setShowDialog] = useState(false);

  const COLUMNS = [
    {
      id: 'checkBox',
      label: <Iconify width={20} icon="carbon:checkbox" />,
      minWidth: 170,
      render: () => <Iconify width={20} icon="carbon:checkbox" />,
    },
    { id: 'slNo', label: 'SNO.', minWidth: 170 },

    { id: 'desc', label: 'Description', minWidth: 100 },
    {
      id: 'pn',
      label: 'P/N',
      minWidth: 170,
    },
    {
      id: 'complianceDeclaration',
      label: 'Compliance Declaration',
      minWidth: 100,
      render: () => (
        <TextField
          size="small"
          sx={{ mb: 1.2 }}
          value={formData?.complianceDeclaration}
          onChange={(e) => onChangeText('complianceDeclaration', e)}
        />
      ),
    },
    {
      id: 'qty',
      label: 'QUANTITY',
      minWidth: 170,
    },
    {
      id: 'deliveryTimeline',
      label: 'Delivery Timeline',
      minWidth: 170,
    },
  ];

  const TABLE_DATA = [
    createData('', 1, 'PELCO 550', 'IWM-SW', 'Same P/N', 10, '24 days'),
    createData('', 2, 'PELCO 550', 'IWM-SW', 'Same P/N', 10, '24 days'),
    createData('', 3, 'PELCO 550', 'IWM-SW', 'Same P/N', 10, '24 days'),
    createData('', 4, 'PELCO 550', 'IWM-SW', 'Same P/N', 10, '3 weeks'),
  ];

  const SECONDARY_COLUMNS = [
    {
      id: 'unitIssue',
      label: 'Unit of Issue',
      minWidth: 170,
      render: () => (
        <Box display="flex" flexDirection="column">
          <FormControl fullWidth>
            <InputLabel>PCS</InputLabel>
            <Select label="PCS" value={formData?.pcs} onChange={(e) => onChangeText('pcs', e)}>
              <MenuItem value="A">PCS_A</MenuItem>
              <MenuItem value="B">PCS_B</MenuItem>
              <MenuItem value="C">PCS_C</MenuItem>
              <MenuItem value="D">PCS_D</MenuItem>
            </Select>
          </FormControl>
          <Typography
            textTransform="uppercase"
            variant="subtitle2"
            component="span"
            sx={{ mx: 0.25, mt: 3.5, textDecoration: 'underline' }}
          >
            Vendor-Name
          </Typography>
        </Box>
      ),
    },
    {
      id: 'hsnCode',
      label: 'HSN Code',
      minWidth: 170,
      render: () => (
        <TextField
          size="small"
          sx={{ mb: 1.2 }}
          value={formData?.hsnCode}
          onChange={(e) => onChangeText('hsnCode', e)}
        />
      ),
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 170,
    },
    {
      id: 'vendorDeliveryTimeline',
      label: 'Vendor Delivery Timeline',
      minWidth: 170,
    },
    {
      id: 'purchaseUnit',
      label: 'Vendor Unit Price',
      minWidth: 170,
      render: (value) => fCurrency(value),
    },
    {
      id: 'purchaseTotal',
      label: 'Vendor Total Price',
      minWidth: 170,
      render: (value) => fCurrency(value),
    },
    {
      id: 'datasheetUpload',
      label: 'Datasheet File Upload',
      minWidth: 170,
      render: () => (
        <Button
          component="label"
          variant="contained"
          startIcon={<Iconify width={20} icon="carbon:upload" />}
        >
          <Typography textTransform="uppercase" variant="button">
            Upload
          </Typography>
          <VisuallyHiddenInput accept="*" onClick={(e) => console.log('e===', e)} type="file" />
        </Button>
      ),
    },
    {
      id: 'certificateConformity',
      label: 'Certificate of Conformity',
      minWidth: 170,
      render: () => (
        <Button
          component="label"
          variant="contained"
          startIcon={<Iconify width={20} icon="carbon:upload" />}
        >
          <Typography textTransform="uppercase" variant="button">
            Upload
          </Typography>
          <VisuallyHiddenInput accept="*" onClick={(e) => console.log('e===', e)} type="file" />
        </Button>
      ),
    },
    {
      id: 'vendorQuoteFile',
      label: 'Vendor Quote File',
      minWidth: 133,
      render: () => (
        <Button
          component="label"
          variant="contained"
          startIcon={<Iconify width={20} icon="carbon:upload" />}
        >
          <Typography textTransform="uppercase" variant="button">
            Upload
          </Typography>
          <VisuallyHiddenInput accept="*" onClick={(e) => console.log('e===', e)} type="file" />
        </Button>
      ),
    },
    {
      id: 'comment',
      label: 'Comment',
      minWidth: 170,
      render: () => (
        <Box onClick={() => setShowDialog(true)} display="flex" flexDirection="column">
          <Box sx={{ ml: 2.5 }}>
            <Iconify width={20} icon="ic:outline-chat" />
          </Box>
          <Typography
            textTransform="uppercase"
            variant="caption"
            component="span"
            sx={{ mr: 0.4, width: 20 }}
          >
            View/Write Comment
          </Typography>
          <TextField
            size="small"
            type="number"
            label="Quantity"
            sx={{ mt: 4 }}
            value={formData?.hsnCode}
            onChange={(e) => onChangeText('hsnCode', e)}
          />
        </Box>
      ),
    },
    {
      id: 'purchasePrice',
      label: 'Purchase Price',
      minWidth: 180,
      render: () => (
        <Box display="flex" flexDirection="column">
          <Chip
            style={{ textTransform: 'uppercase' }}
            sx={{ width: 93, bgcolor: primaryPresets[4].light, color: 'black', mb: 2.4 }}
            label="Review"
          />
          <Button component="label" variant="contained">
            <Typography textTransform="uppercase" variant="button">
              Generate PO
            </Typography>
          </Button>
        </Box>
      ),
    },
  ];

  const SECONDARY_TABLE_DATA = [
    createSecondaryData(1, '', 'PELCO', '24 days', 10, 10, '', '', '', '', ''),
    createSecondaryData(1, '', 'PELCO', '24 days', 10, 10, '', '', '', '', ''),
    createSecondaryData(1, '', 'PELCO', '24 days', 10, 10, '', '', '', '', ''),
    createSecondaryData(1, '', 'PELCO', '24 days', 10, 10, '', '', '', '', ''),
  ];

  const onChangeText = (field, val) => {
    setFormData({ ...formData, [field]: val.target.value });
  };

  return (
    <Box>
      <CollapsableTable
        colSpan={8}
        primaryColumns={COLUMNS}
        primaryTableData={TABLE_DATA}
        secondaryColumns={SECONDARY_COLUMNS}
        secondaryTableData={SECONDARY_TABLE_DATA}
      />
      <CommentDialog open={showDialog} handleClose={setShowDialog} />
    </Box>
  );
}
