import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { HasAccess, usePermify } from '@permify/react-role';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';
import moment from 'moment';
import { Divider, Typography } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { useEffect, useState } from 'react';
import { GET_CLIENT_LOGO } from 'src/services/clientInfo';
import AddTaskIcon from '@mui/icons-material/AddTask';
// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { user } = useAuthContext();

  const [isVendor, setIsVendor] = useState(true);
  const [backendAuth, setBackendAuth] = useState(false);


  const settings = useSettingsContext();
  const { isAuthorized, isLoading } = usePermify();

  useEffect(() => {
    const callAuth = async() => {
      let tempAuth = await isAuthorized([],"backend");
      await setBackendAuth(tempAuth)
    }
    callAuth();
  }, [])
  

  useEffect(() => {
    if (user?.role?.includes('vendor')) {
      setIsVendor(true);
    } else {
      setIsVendor(false);
    }
  }, [user]);

  const [clientLogo, setClientLogo] = useState('');
  useEffect(() => {
    //TODO: need to change based on client logic if we have
    GET_CLIENT_LOGO('Airbus Defence and Space GmbH, Branch SA')
      .then((res) => {
        setClientLogo(res[0]?.clientlogo);
        // setNotifications(res.data.notifications);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const renderContent = (
    <>
      {lgUp && (
        <>
          <Logo sx={{ mr: 2.5 }} />
          {clientLogo ? (
            <>
              {isVendor ? (
                <svg
                  width="8"
                  height="40"
                  viewBox="0 0 8 63"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_3385_11742"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="8"
                    height="63"
                  >
                    <path d="M4 0L4 63" stroke="#FBC47C" stroke-width="8" />
                  </mask>
                </svg>
              ) : (
                <>
                  <svg
                    width="8"
                    height="40"
                    viewBox="0 0 8 63"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_3385_11742"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="8"
                      height="63"
                    >
                      <path d="M4 0L4 63" stroke="#FBC47C" stroke-width="8" />
                    </mask>
                    <g mask="url(#mask0_3385_11742)">
                      <path
                        d="M-32.335 -11.5723L4.09208 -11.5723L-14.3803 9.48983L-32.335 -11.5723Z"
                        fill="#EC1E87"
                      />
                      <path
                        d="M4.0896 -11.5723L22.0442 9.48983L-14.3828 9.48983L4.0896 -11.5723Z"
                        fill="#68318F"
                      />
                      <path
                        d="M-14.3803 9.49024L-32.335 30.725L4.09207 30.725L-14.3803 9.49024Z"
                        fill="#5CC7D0"
                      />
                      <path
                        d="M22.0442 9.49024L-14.3828 9.49023L4.0896 30.725L22.0442 9.49024Z"
                        fill="#454CA0"
                      />
                      <path
                        d="M-32.335 30.7246L-14.2077 52.132L4.09208 30.7246L-32.335 30.7246Z"
                        fill="#A4D9D1"
                      />
                      <path
                        d="M22.0454 9.49024L4.09082 30.725L40.0001 30.725L22.0454 9.49024Z"
                        fill="#EE4423"
                      />
                      <path
                        d="M40.0001 30.7246L22.0454 52.132L4.09082 30.7246L40.0001 30.7246Z"
                        fill="#F89C26"
                      />
                      <path
                        d="M-14.21 52.132L22.0445 52.132L4.08985 30.7246L-14.21 52.132Z"
                        fill="#448AC8"
                      />
                      <path
                        d="M3.91725 73.3675L-14.21 52.1328L22.0445 52.1328L3.91725 73.3675Z"
                        fill="#2BC5EF"
                      />
                      <path
                        d="M-32.6816 73.3675L3.9181 73.3675L-14.2091 52.1328L-32.6816 73.3675Z"
                        fill="#F0EA29"
                      />
                    </g>
                  </svg>
                  <img
                    style={{ marginLeft: '20px' }}
                    width="150"
                    heigth="17"
                    src={clientLogo}
                    alt="client logo"
                  />
                </>
              )}
            </>
          ) : (
            <svg
              width="8"
              height="40"
              viewBox="0 0 8 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_3385_11742"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="8"
                height="63"
              >
                <path d="M4 0L4 63" stroke="#FBC47C" stroke-width="8" />
              </mask>
            </svg>
          )}
        </>
      )}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
        <Typography variant="h5" sx={{ px: 3, display: 'flex', alignItems: 'center' }}>
          <AddTaskIcon />
          &nbsp; TASKS
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
        <HasAccess roles={['admin']} permissions={['admin_vendor_penal_access']}>
        <Typography variant="h5" sx={{ px: 3 }}
        >
          <a  href={`/vendor/login`} target='_blank'>
          VENDOR
          </a>
        </Typography>
        </HasAccess>
        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
        {/* <LanguagePopover /> */}
        {
          backendAuth === false ? 
            <HasAccess roles={['admin']} permissions={['admin_notification_view']}>
              <NotificationsPopover />
            <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />
            </HasAccess> : null

        }
        <Stack direction="column">
          <Typography variant="h5">
            <b>Date</b>
          </Typography>
          <Typography variant="caption">{moment().format('DD-MM-YY')}</Typography>
          <Typography variant="h5">
            <b>Time</b>
          </Typography>
          <Typography variant="caption">{moment().format('HH:mm:ss')}</Typography>
        </Stack>
        <Typography variant="h5" sx={{ px: 3 }}>
          {moment().format('dddd, YYYY')}
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        background: 'white',
        // ...bgBlur({
        //   color: theme.palette.background.default,
        // }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
