import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Scrollbar from '../scrollbar';
import { TablePaginationCustom, useTable } from '../table';
import Row from './row';

export default function CollapsableTable(props) {
  const {
    primaryColumns,
    primaryTableData,
    secondaryColumns,

    colSpan,
    loading = false,
    enableCheckBox = true,
  } = props || {};
  const table = useTable({ defaultRowsPerPage: 10 });
  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = primaryTableData.map((n) => n?.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (_event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <Paper elevation={1}>
      <TableContainer sx={{ overflow: 'unset' }}>
        <Scrollbar sx={{ maxHeight: 800 }}>
          <Table stickyHeader sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                <TableCell />
                {enableCheckBox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < primaryTableData.length
                      }
                      checked={
                        primaryTableData.length > 0 && selected.length === primaryTableData.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {primaryColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      textTransform: 'uppercase',
                      border: 1,
                    }}

                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      width="100%"
                      height={220}
                    >
                      <CircularProgress />
                      <br />
                      <Typography textTransform="uppercase">Fetching...</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {primaryTableData.map((row, index) => {
                  const isItemSelected = isSelected(row?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row
                      colSpan={colSpan}
                      key={row?.code}
                      secondaryColumns={secondaryColumns}
                      row={{ ...row, slNo: index + 1 }}
                      columns={primaryColumns}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                      enableCheckBox={enableCheckBox}
                    />
                  );
                })}
              </TableBody>
            )}
          </Table>
        </Scrollbar>
      </TableContainer>
    </Paper>
  );
}
