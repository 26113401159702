import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';
import { primaryPresets } from 'src/theme/options/presets';
import { fCurrency } from 'src/utils/format-number';

function createData(
  checkBox,
  projectId,
  purchaseOrder,
  partNo,
  qtyNo,
  vendor,
  make,
  contractAmount,
  status,
  CommercialInvoiceNumber
) {
  return {
    checkBox,
    projectId,
    purchaseOrder,
    partNo,
    qtyNo,
    vendor,
    make,
    contractAmount,
    status,
    CommercialInvoiceNumber,
  };
}

export default function CommercialInvoiceDialog(props) {
  const table = useTable({ defaultRowsPerPage: 10 });
  const { open, handleClose } = props || {};

  const COLUMNS = [
    {
      id: 'checkBox',
      label: <Iconify width={20} icon="carbon:checkbox" />,
      minWidth: 50,
      render: () => <Iconify width={20} icon="carbon:checkbox" />,
    },
    {
      id: 'projectId',
      label: 'PROJECT ID',
      minWidth: 130,
    },
    {
      id: 'purchaseOrder',
      label: 'PURCHASE ORDER',
      minWidth: 150,
    },
    {
      id: 'partNo',
      label: 'PART NO.',
      minWidth: 150,
    },
    {
      id: 'qtyNo',
      label: 'QTY NO.',
      minWidth: 120,
    },
    {
      id: 'vendor',
      label: 'Vendor',
      minWidth: 120,
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 100,
    },
    {
      id: 'contractAmount',
      label: 'Contract Amount',
      minWidth: 170,
      render: (value) => fCurrency(value),
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 170,
      render: () => (
        <Chip style={{textTransform:"uppercase"}} sx={{ borderRadius: 0, bgcolor: primaryPresets[6].dark }} label="DELIVERED" />
      ),
    },
    {
      id: 'CommercialInvoiceNumber',
      label: 'COMMERCIAL INVOICE NUMBER',
      minWidth: 170,
      render: () => (
        <Typography textTransform="uppercase" variant="caption" style={{ textDecoration: 'underline' }}>
          PROJECT ID-01-CNIV
        </Typography>
      ),
    },
  ];

  const TABLE_DATA = [
    createData('', 1, 123456748, 'IWM-SW', 10, 'ABC EXPORTERS', 'Pelco', 1000, '', ''),
    createData('', 1, 123456748, 'IWM-SW', 10, 'ABC EXPORTERS', 'Pelco', 1000, '', ''),
    createData('', 1, 123456748, 'IWM-SW', 10, 'ABC EXPORTERS', 'Pelco', 1000, '', ''),
    createData('', 1, 123456748, 'IWM-SW', 10, 'ABC EXPORTERS', 'Pelco', 1000, '', ''),
  ];

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={() => handleClose(false)}>
      <DialogTitle variant="h4" style={{ textDecoration: 'underline' }} alignSelf="center">
        COMMERCIAL INVOICE DOWNLOAD
      </DialogTitle>
      <DialogContent>
        <Paper>
          <TableContainer>
            <Scrollbar>
              <Table stickyHeader sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, textTransform: 'uppercase', border: 1 }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {TABLE_DATA.slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  ).map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {COLUMNS.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{ verticalAlign: 'top',textTransform:"uppercase"  }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.render ? column.render(value, row) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={TABLE_DATA.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Paper>
      </DialogContent>
    </Dialog>
  );
}
