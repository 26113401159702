import { Helmet } from 'react-helmet-async';
import VendorQuoteSubmissionView from 'src/sections/vendor-quote/view/vendorQuoteSubmission';
// sections

// ----------------------------------------------------------------------

export default function VendorQuoteSubmissionPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Vendor Quote Submission</title>
      </Helmet>

      <VendorQuoteSubmissionView />
    </>
  );
}
