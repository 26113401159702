// @mui
import { alpha } from '@mui/material/styles';
// theme
import { palette as themePalette } from 'src/theme/palette';

// ----------------------------------------------------------------------

export function presets(presetsColor) {
  const primary = primaryPresets.find((i) => i.name === presetsColor);

  const theme = {
    palette: {
      primary,
    },
    customShadows: {
      primary: `0 8px 16px 0 ${alpha(`${primary?.main}`, 0.24)}`,
    },
  };

  return theme;
}

// ----------------------------------------------------------------------

const palette = themePalette('light');

export const primaryPresets = [
  // DEFAULT 0
  {
    name: 'default',
    ...palette.primary,
  },
  // CYAN 1
  {
    name: 'cyan',
    lighter: '#CCF4FE',
    light: '#68CDF9',
    main: '#078DEE',
    dark: '#0351AB',
    darker: '#012972',
    contrastText: '#FFFFFF',
  },
  // PURPLE 2
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    temp: '#95AFC6',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#FFFFFF',
    darkest: '#9797FF',
    mild: '#9797ff78',
  },
  // BLUE 3
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#FFFFFF',
    darkest: '#7AA3F3',
  },
  // ORANGE 4
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.grey[800],
  },
  // RED 5
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#FFFFFF',
  },
  // Green 6
  {
    name: 'green',
    main: '#00F400',
    light: '#7DE7CA',
    lighter: '#0080001a',
    dark: '#008000',
    mint: '#CBF5EA',
  },
  // PINK 7
  {
    name: 'pink',
    main: '#FF8787',
    dark: '#f44336',
    light: '#FEC3C3',
  },
  // Brown 8
  {
    name: 'brown',
    light:"#FBC47C",
    lighter:'#fbc47c66'
  },
  // Yellow 9
  {
    name:"yellow",
    light:"#FBF385",
    main:"#ffc107",
    lighter:'#fbf38554',
    dark:"#263039"
  },
  //Gray 10
  {
    name: 'gray',
    light: '#A9A9A9',
  },
  //Black 11
  {
    name: 'black',
    main: '#000000',
  },
];
