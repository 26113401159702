import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { IconButton, LinearProgress } from '@mui/material';
import { getSignedUploadURl, getSignedURltoviewFile } from 'src/services/file';
import axiosInstance from 'src/utils/axios';
import axios from 'axios';
import RejectionFiles from './errors-rejection-files';
import { fileNameByUrl } from 'src/components/file-thumbnail/utils';

import Iconify from '../iconify';
import PdfPreviewPopup from '../common/pdfPreview';

// ----------------------------------------------------------------------
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function VIewFile({
  disabled,
  label,
  helperText,
  sx,
  path,
  setValue,

  ...other
}) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setError(other.error);

    setFile(other.value);
  }, [other.error, other.value]);

  const handleUpload = async (File) => {
    if (!File) {
      alert('Please select a file to upload');
      return;
    }

    try {
      // Replace 'your-backend-api-endpoint' with the actual endpoint to get the signed AWS URL
      const urlIncoded = File?.name?.split(" ").join("");
      const response = await getSignedUploadURl(`${path}/${urlIncoded}`);

      if (response) {
        const { signedUrl } = response;

        const formData = new FormData();
        formData.append('file', File);

        const uploadResponse = await axios.put(signedUrl, File, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },

          onUploadProgress: (event) => {
            const percentCompleted = (event.loaded / event.total) * 100;
            setProgress(percentCompleted);
          },
        });

        if (uploadResponse.status === 200) {
          setProgress(0);

          setValue(`${path}/${urlIncoded}`);
        } else {
          alert('Failed to upload file');
        }
      } else {
        alert('Failed to get signed URL');
      }
    } catch (er) {
      setProgress(0);
      setError('Error:', error);
      // console.log(er);
      alert('Failed to communicate with the server');
    }
  };
  const onDrop = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    handleUpload(selectedFile);
  };
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled: progress > 0,
    // accept: {
    //   'image/*': [],
    // },
    onDrop,
  });

  const hasFile = !!file;
  return (
    <Stack sx={sx}>
      {/* <Button
        style={{ textTransform: 'uppercase' }}
        onClick={async (e) => {
          e.stopPropagation();
          if (typeof file !== 'string') window.open(URL.createObjectURL(file));
          else window.open(await getSignedURltoviewFile(file));
        }}
        disabled={disabled}
        variant="outlined"
      >
        View {label}
      </Button> */}
      <PdfPreviewPopup pdfUrl={file} label={`VIEW ${label}`}/>
      <Typography
        variant="body"
        style={{ textDecoration: 'underline', cursor: 'pointer', wordBreak: 'break-word' }}
      >
        {hasFile ? fileNameByUrl(file) : ''}
      </Typography>
    </Stack>
  );
}
