import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { LinearProgress } from '@mui/material';
import { getSignedUploadURl, getSignedURltoviewFile } from 'src/services/file';
import axiosInstance from 'src/utils/axios';
import RejectionFiles from './errors-rejection-files';
import axios from 'axios';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function UploadAvatar({
  disabled,
  label,
  helperText,
  sx,
  path,
  setValue,
  ...other
}) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadedUrl, setUploadedUrl] = useState(null);

  useEffect(() => {
    setError(other.error);
    setUploadedUrl(other.value);
  }, [other.error, other.value]);

  const handleUpload = async (File) => {
    if (!File) {
      alert('Please select a file to upload');
      return;
    }

    try {
      // Replace 'your-backend-api-endpoint' with the actual endpoint to get the signed AWS URL
      const response = await getSignedUploadURl(`${path}/${File.name}`);

      if (response) {
        const { signedUrl } = response;

        const formData = new FormData();
        formData.append('file', File);

        const uploadResponse = await axios.put(signedUrl, formData, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          onUploadProgress: (event) => {
            const percentCompleted = (event.loaded / event.total) * 100;
            setProgress(percentCompleted);
          },
        });

        if (uploadResponse.status === 200) {
          setProgress(0);
          setUploadedUrl(signedUrl);
          setValue(`${path}/${File.name}`);
        } else {
          alert('Failed to upload file');
        }
      } else {
        alert('Failed to get signed URL');
      }
    } catch (er) {
      setProgress(0);
      setError('Error:', error);
      alert('Failed to communicate with the server');
    }
  };
  const onDrop = async (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    handleUpload(selectedFile);
  };
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled: progress > 0,
    // accept: {
    //   'image/*': [],
    // },
    onDrop,
  });

  const hasFile = !!file;

  const hasError = isDragReject || !!error;

  const renderPreview = hasFile && (
    <>
      <Iconify icon="fa6-regular:file" alt="avatar" width={32} sx={{ mb: 1 }} />
      <Typography textTransform="uppercase" variant="caption">1 file attached </Typography>
      <Box mt={2}>
        <center>
          <Button style={{ textTransform: 'uppercase' }}
            onClick={async (e) => {
              e.stopPropagation();
              if (typeof file === 'string') window.open(URL.createObjectURL(file));
              else window.open(await getSignedURltoviewFile(file));
            }}
            variant="outlined"
            size="small"
          >
            View file
          </Button>
        </center>
      </Box>
    </>
  );

  const renderPlaceholder = (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      className="upload-placeholder"
      sx={{
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 9,
        borderRadius: '10%',
        position: 'absolute',
        color: 'text.disabled',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        '&:hover': {
          opacity: 0.72,
        },
        ...(hasError && {
          color: 'error.main',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }),
        // ...(hasFile && {
        //   zIndex: 9,
        //   opacity: 0,
        //   color: 'common.white',
        //   bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64),
        // }),
      }}
    >
      <Iconify icon="octicon:upload-16" width={32} />

      <Typography textTransform="uppercase" variant="caption">{file ? `Update ${label}` : `Upload ${label}`}</Typography>
    </Stack>
  );

  const renderContent = (
    <Box
      sx={{
        width: 1,

        overflow: 'hidden',
        borderRadius: '10%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {renderPreview}
      {!hasFile && renderPlaceholder}
    </Box>
  );

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          p: 1,
          //   m: 'auto',
          width: 1,
          height: 144,
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: '10%',
          border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            borderColor: 'error.main',
          }),
          ...(hasFile && {
            ...(hasError && {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            }),
            '&:hover .upload-placeholder': {
              opacity: 1,
            },
          }),
          ...sx,
        }}
      >
        {!hasFile && <input {...getInputProps()} />}

        {renderContent}
      </Box>

      {helperText && helperText}
      {progress > 0 ? <LinearProgress variant="determinate" value={progress} /> : ''}
      <Typography textTransform="uppercase" variant="caption" sx={{ color: 'error.main', pl: 2, pt: 1 }}>
        {error}
      </Typography>
      <RejectionFiles fileRejections={fileRejections} />
    </Box>
  );
}

UploadAvatar.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.object,
  sx: PropTypes.object,
};
