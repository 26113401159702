import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import classes from './comments.module.css';
import { GET_COMMENTS_LIST, ADD_COMMENTS } from 'src/services/comment';
import { useAuthContext } from 'src/auth/hooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
};

const FormattedDateTime = (time) => {
  const dateObj = new Date(time);

  const formattedDate = dateObj.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
  });

  const formattedTime = dateObj.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
};
export default function CommentsModal({ isCommentOpen, setIsCommentOpen, id }) {
  const { user } = useAuthContext();
  const [newMessage, setNewMessage] = useState('');
  const handleClose = () => setIsCommentOpen(false);
  const [commentsData, setCommentsData] = useState(null);
  const [isAdded, setIsAdded] = useState(null);

  const handleSendMessage = async (event) => {
    event.preventDefault();
    setNewMessage('');
    const Payload = {
      refId: id,
      comment: newMessage,
    };

    try {
      const response = await ADD_COMMENTS(Payload);
      setIsAdded(response);
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  useEffect(() => {
    if (isCommentOpen) {
      GET_COMMENTS_LIST(id, 1, 5)
        .then((response) => {
          setCommentsData(response?.data);
        })
        .catch(() => {});
    }
  }, [id, isCommentOpen, isAdded]);

  return (
    <div>
      <Modal
        open={isCommentOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton aria-label="close" style={closeButtonStyle} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Comments
          </Typography>
          <div
            style={{
              padding: '10px',
              overflowY: 'auto',
              maxHeight: '400px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {commentsData?.comments?.map((comment, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: comment?.userId === user?._id ? 'flex-end' : 'flex-start',
                  marginBottom: '10px',
                }}
              >
                <div
                  className={comment?.userId === user?._id ? classes.sender : classes.receiver}
                  style={{
                    padding: '10px',
                    maxWidth: '400px',
                    minWidth: '400px',
                  }}
                >
                  <Typography variant="body1">
                    <div className={classes.userName}>
                      {comment?.username && `${comment?.username} `}
                    </div>
                    {comment?.comment}
                  </Typography>
                  <Typography variant="caption" style={{ textAlign: 'right' }}>
                    <div>{FormattedDateTime(comment?.addedAt)}</div>
                  </Typography>
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Type your message"
              variant="outlined"
              fullWidth
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <IconButton
              aria-label="send"
              type="button"
              onClick={(event) => handleSendMessage(event)}
              style={{
                height: '50px',
                width: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SendIcon />
            </IconButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
