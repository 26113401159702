import React from 'react';

import { LoadingButton } from '@mui/lab';

export default function LoadingAsync({
  loadFunc,
  label,
  AlternativeView,
  buttonProps,
  handleResponse,
  handleErr,
  params,
}) {
  const [loadding, setLoading] = React.useState(false);
  const callFunction = () => {
    setLoading(true);
    loadFunc(...params)
      .then((res) => {
        handleResponse(res);
        setLoading(false);
      })
      .catch((err) => {
        handleErr(err);
        setLoading(false);
      });
  };

  return (
    <>
      {AlternativeView ? (
        AlternativeView
      ) : (
        <LoadingButton style={{textTransform:"uppercase"}} loading={loadding} onClick={callFunction} {...buttonProps}>
          {label}
        </LoadingButton>
      )}
    </>
  );
}
