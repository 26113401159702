import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { primaryPresets } from 'src/theme/options/presets';
import { CurrencyPicker } from 'src/components/currency-picker/currency-picker';
import VendorQuoteOrdersTable from '../vendorQuoteOrdersTable';
import { useParams, useRouter } from 'src/routes/hooks';
import VendorQuotesNav from 'src/components/Navs/VendorQuotesNav';
export default function VendorQuoteArchiveView() {
  const settings = useSettingsContext();
  const { id, partId ,vid} = useParams();
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      {vid ?
      <CustomBreadcrumbs
        heading="Vendor Quote"
        links={[
          { name: 'Home', href: '/' },
          {
            name: 'Vendor',
            href: `/quote/${id}/vendor`,
          },
          {
            name: 'Vendor Quote Archive',
            href: '/vendor-quote/vendor-quote-archive',
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />:<CustomBreadcrumbs
        heading="Vendor Quote"
        links={[
          { name: 'Home', href: '/' },
          {
            name: 'Vendor Quote Archive',
            href: '/vendor-quote/vendor-quote-archive',
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />}
      <Box sx={{ mb: 2 }} display="flex" justifyContent="space-between">
        <VendorQuotesNav id={id} url={`/${id}/${partId}`} vid={vid} currentTab={"archive"}/>
      </Box>

      <VendorQuoteOrdersTable />
    </Container>
  );
}
